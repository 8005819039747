@font-face {
  font-family: 'Oswald-Bold';
  src: url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /* url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.woff2') format('woff2'),  Modern Browsers */
       url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
    /* url('./fonts/Oswald/Oswald-Bold/fonts/Oswald-Bold.svg') format('svg');  Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
