/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald/Oswald-Regular/fonts/Oswald-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Oswald/Oswald-Regular/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /* url('./fonts/Oswald/Oswald-Regular/fonts/Oswald/Oswald-Bold/fonts/Oswald-Regular.woff2') format('woff2'), Modern Browsers */
       url('./fonts/Oswald/Oswald-Regular/fonts/Oswald-Regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Oswald/Oswald-Regular/fonts/Oswald-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
    /* url('./fonts/Oswald/Oswald-Regular/fonts/Oswald-Regular.svg') format('svg'); Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
