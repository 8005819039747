  .datafor--dropdown--wrapper{
    position: relative;
    z-index: 101;
    height: 5rem;
    > label{
      min-height:5rem;
      &:empty{
        display:none;
      }
    }
    .datafor--dropdown--list{
      position: absolute;
      height: 4rem;
      border: 1px solid $gray-light;
      background: $white;
      color:$gray;
      overflow: hidden;
      width: auto;
      padding: 0rem;
      .option-title{
        padding:0.8rem 6rem 0.8rem 0.8rem;
        margin: 0;
        font-size: 1.6rem;
        cursor:pointer;
        position: relative;
        &:after{
          content: "\f107";
          display: inline-block;
          font-size:2.5rem !important;
          font-family:'FontAwesome';
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          right: 0.8rem;
          top: 0.2rem;
        }
      }
      .datafor--dropdown--options{
        list-style-type: none;
        padding: 1rem;
        margin: 0;
        font-size: 1.6rem;
        font-weight: normal;
        max-height: 20rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }       
        &::-webkit-scrollbar-track {
            border-radius: 4px;
        }       
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: #CCC;
        }
        li{
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;
          label{
            font-weight: normal;
            padding-left: 0.8rem;
            margin-bottom: 0;
            max-width: 90%;
          }
          input{
            margin: 0;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }    
    }
    &.viewOptions{
      .datafor--dropdown--list{
        overflow: visible;
        display: block;
        height: auto;
        .option-title{
          &:after{
            content: "\f106";
          }
        }
      }
    }      
  }
  .comparisons-filter{
    .block--typechart--wrapper{
      .filter-text{
        label{

        }
        select, select:focus{
          max-width: 100%;
          width: auto;
          option{
            font-size: 1.6rem;
          }
        }
      }
    }
  }
/** TAGS */
  .selected--tags-wrapper{
    min-height: 0px;
    margin-top: 0rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 100;
    &:empty{
      display:none;
    } 
    .selected-tag {
      color: $aquamarine;
      margin: 0.8rem 0rem 0.6rem 0.6rem;
      padding: 2px 1rem;
      display: inline-block;
      position: relative;
      font-family: "Opensans-bold";
      &:before{
        //content: "\f00d";
        font-family: 'FontAwesome';
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        cursor: pointer;
        &:hover{
          opacity:0.6;
          color: $orange;
        }
      }
    }
  }
/** END TAGS */

.comparisons-chart{
  margin: 4rem 0;
}