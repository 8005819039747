@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans/OpenSans-Regular/fonts//OpenSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/OpenSans/OpenSans-Regular/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /* url('./fonts/OpenSans/OpenSans-Regular/fonts/OpenSans-Regular.woff2') format('woff2'), Modern Browsers */
       url('./fonts/OpenSans/OpenSans-Regular/fonts/OpenSans-Regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/OpenSans/OpenSans-Regular/fonts/OpenSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
    /* url('./fonts/OpenSans/OpenSans-Regular/fonts/OpenSans-Regular.svg') format('svg'); Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
