.home-cover {
  background: url(../style/img/home-header-bg-without.png) left bottom no-repeat;
  background-size: cover;
  //height:20.4rem;
  min-height:14rem;
  clear: both;
  text-align: center;
  cursor: pointer;
  > .container-fluid{
    //width: 100%;
    //padding: 0;
  }
  .main-title{
    font-family: Oswald-light,sans-serif;
    line-height: 4rem;
    padding: 3rem 0 0 0;
    margin: 0rem auto;
    color: #00309c;
    width:fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 5.6rem;
    }
    .main-text{
      //vertical-align: middle;
      font-size: 4.6rem;
      line-height: initial;
      padding: 0rem 0 0rem 0rem;
    }
  }
  .lead-title{
    font-family: Oswald-Light,sans-serif;
    font-size: 4.6rem;
    padding: 0rem 0 0rem 2rem;
    margin-left: 2rem;
    width: auto;
    //vertical-align: middle;
    line-height: initial;
    display: inline-block;
    border-left: 3px solid $orange;
    text-align: left;
  }
}
.home {
  .title--section.multiline {
    a[target="_blank"]{
      display:inline;
    }
  }
}
.carousel-track{
  height: 280px !important;
}

/** ICONS */
  .forum-icon{
    background: url(../style//img/forum.png) center top no-repeat;
    background-size: contain;
  }
  .economic-chart-icon{
    background: url(../style//img/economic-chart.png) center top no-repeat;
    background-size: contain;
  }
  .people-group-icon{
    background: url(../style//img/people-group.png) center top no-repeat;
    background-size: contain;
  }
  .culture-icon{
    background: url(../style//img/culture.png) center top no-repeat;
    background-size: contain;
  }
  .dialogue-icon{
    background: url(../style//img/dialogue.png) center top no-repeat;
    background-size: contain;
  }
  .enforcement-icon{
    background: url(../style//img/enforcement.png) center top no-repeat;
    background-size: contain;
  }
  .health-icon{
    background: url(../style//img/health.png) center top no-repeat;
    background-size: contain;
  }
  .national-icon{
    background: url(../style//img/national.png) center top no-repeat;
    background-size: contain;
  }
  .prevention-icon{
    background: url(../style//img/prevention.png) center top no-repeat;
    background-size: contain;
  }
  .regulation-icon{
    background: url(../style//img/regulation.png) center top no-repeat;
    background-size: contain;
  }
  .statistics-icon{
    background: url(../style//img/statistics.png) center top no-repeat;
    background-size: contain;
  }
  .working-conditons-icon{
    background: url(../style//img/working-conditons.png) center top no-repeat;
    background-size: contain;
  }
  .work-accidents-icon{
    background: url(../style//img/work-accidents.png) center top no-repeat;
    background-size: contain;
  }
  .workers-icon{
    background: url(../style//img/workers.png) center top no-repeat;
    background-size: contain;
  }
  .mental-risk-icon{
    background: url(../style//img/mental-risk.png) center top no-repeat;
    background-size: contain;
  }
  .physical-risk-icon{
    background: url(../style//img/physical-risk.png) center top no-repeat;
    background-size: contain;
  }


  /*** ESENER *****/
  .osh-management-icon{
    background: url(../style//img/osh-management.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/osh-management-hover.png) center 3rem no-repeat;
    }
  }
  .psychosocial-risk-icon{
    background: url(../style//img/psychosocial-risk.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/psychosocial-risk-hover.png) center 3rem no-repeat;
    }
  }
  .drivers-barriers-icon{
    background: url(../style//img/drivers-barriers.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/drivers-barriers-hover.png) center 3rem no-repeat;
    }
  }
  .workers-participation-icon{
    background: url(../style//img/workers-participation.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/workers-participation-hover.png) center 3rem no-repeat;
    }
  }
  .european-maps-icon{
    background: url(../style//img/european-maps.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/european-maps-hover.png) center 3rem no-repeat;
    }
  }
  .eu-bar-chart-icon{
    background: url(../style//img/eu-bar-chart.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/eu-bar-chart-hover.png) center 3rem no-repeat;
    }
  }
  .national-bar-chart-icon{
    background: url(../style//img/national-bar-chart.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/national-bar-chart-hover.png) center 3rem no-repeat;
    }
  }
  .national-comparision-icon{
    background: url(../style//img/national-comparision.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
    background: url(../style//img/national-comparision-hover.png) center 3rem no-repeat;
    }
  }
  .pie-chart-icon{
    background: url(../style//img/pie-chart.png) center 3rem no-repeat;
    background-size: contain;
    &:hover{
     background: url(../style//img/pie-chart-hover.png) center 3rem no-repeat; 
    }
  }

/** END ICONS **/