.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}



/** HEADER **/
  .bar-header{
    position: fixed;
    z-index: 1001;
    top: 0;
    padding: 0;
    width: 100%;
    background: #FFF;
    // height:233px;
    // border-bottom: 1px solid #f6a400;
    .header--top{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      .eu-osha-logo{
        display: flex;
        align-items: center;
        a {
          display: block;
          padding: 1rem 1rem 1rem 2rem;
          img{
            //height:9rem;
          }
          &:nth-child(2) {
            padding: 1rem 1rem 1rem 0.5rem;
          }
        }
      }
      .nav--tools--menu {
        display: flex;
        align-items: center;
        margin-left: auto;
      }
      .menu-tools-top{
        padding: 0;
        li{
          vertical-align: middle;
          /*
          padding-left: 3rem;
          padding-right: 0;
          */
         &:nth-child(1){
          //padding-right: 3rem;
         }
        }
        .menu-tools-top--items{
          margin: 0;
          .zoom--text{
            padding-bottom: 0.5rem;
            position: relative;
            top: 1px;
            display: none;
            span {
              display: inline-block;
              vertical-align: sub;
              padding-left: 0.4rem;
              a{
                display: block;
                font-weight: bold;
                cursor: pointer;
                text-decoration: none;
                color:$gray-icon;
                &:hover{
                  text-decoration: none;
                }
              }
              &.a_small a{
                font-size:1em;
              }
              &.a_medium a{
                font-size: 1.5em;
              }
              &.a_big a{
                font-size:2em;
              }
            }       
          }
          .print--block{
            display: none;
            a{
              cursor: pointer;
              text-decoration: none;
              color: $gray-icon;
              font-size: 2.5rem;
              position: relative;
              top: 2px;
              .fa-print:before {
                content: url(../style/img/print-icon.png);
              }
              &:before{
              }
            }
          }
          .lang--block{
            position: relative;
            right: 2.7rem;
            display: flex;
            align-items: center;
            .country-code {
              font-family: 'Roboto-bold', sans-serif;
              text-transform: uppercase;
              color: $aquamarine;
            }
            .lang--form{
              select{
                margin: 1rem 0 1rem 1rem;
                padding: 0.5rem 1rem 0.5rem 0.5rem;
                border: 0;
                position: relative;
                color: $aquamarine !important;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                font-size: 1.5rem;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: var(--white);
                cursor: pointer;
                &::-ms-expand {
                  display: none;
                }
                option{
                  padding: 0.5rem 0;
                }
              }
              &:after{
                // content: '';
                // position: absolute;
                // top: 1.1rem;
                // padding: 1rem;
                // width: 2.2rem;
                // height: 2.2rem;
                // right: -2rem;
                // background: url(../style/img/lang-icon.png);
                // background-size: 2.2rem;
              }
            }
            &:after {
              content: "\f107";
              font-family: FontAwesome;
              font-weight: normal;
              font-size: 2.6rem;
              color: $orange;
              right: 0.5rem;
              top: -0.2rem;;
              height: auto;
              padding: 1rem;
              position: absolute;
              pointer-events: none;
            }     
          }
        }
      }
    }
    .bar-main-menu{
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 0px;
      padding: 0;  
      width: 100%;  
      border-top: 1px solid #ccc; 
      border-bottom: 1px solid #ccc; 
      .navbar-nav{
        flex-direction: row;
        position: relative;
        display: flex;
        width: 100%;
        float: none;
        justify-content: center;
        > li {
          &.main-menu-selected{                      
            box-shadow: none !important;
            > a {
              color: $aquamarine;
            }
            &:after {
              content:" ";
              display: block;
              width: 100%;
              height: 4px;
              background: $orange;
              position: relative;
              bottom: -2px;
            }
          }
          &:hover{
            box-shadow: none !important;
            -webkit-transition: border-color 0.4s ease-out;
            -moz-transition: border-color 0.4s ease-out;
            -o-transition: border-color 0.4s ease-out;
            transition: border-color 0.4s ease-out;
            &:after {
              content:" ";
              display: block;
              width: 100%;
              height: 4px;
              background:$aquamarine;
              position: relative;
              bottom: -2px;
            }
          }
          a {
            display: flex;
            align-items: center;
            font-family: 'Roboto-Medium', sans-serif;
            color:$link-menu;
            font-size:1.5rem;
            font-weight: 400;
            padding: 1.6rem 2.6rem 1.2rem 2.6rem;
            -webkit-transition: color 0.8s ease-out;
            -moz-transition: color 0.8s ease-out;
            -o-transition: color 0.8s ease-out;
            transition: color 0.8s ease-out;
            .fa-home:before{
                font-size: 2.5rem;
            }
            > i {
              font-size: 2.6rem;
              line-height: 0.5 !important;
              position: relative;
              left: 1rem;
              color: $orange;
              transform: rotate(0deg);
              transition: all 0.2s ease-out;
            }
            &:hover{
              color: $black;
              -webkit-transition: color 0.8s ease-out;
              -moz-transition: color 0.8s ease-out;
              -o-transition: color 0.8s ease-out;
              transition: color 0.8s ease-out;
            }
            &:focus, &:focus-visible, &:visited{
              background-color:transparent !important;
              outline: none;
            }
          }
          &.dropdown {
            .fa-caret-down:before{
              color:$aquamarine;
            }
            > a {
              background:transparent !important;
              &.dropdown-toggle{
                &::after{
                  display: none;
                }
                &:hover {
                  background:transparent !important;
                  > i {
                    transform: rotate(180deg);
                    transition: all 0.2s ease-out;
                    color:$aquamarine;
                  }
                }
              }
            }
            &:hover{
              .dropdown-menu{
                border-top: 4px solid $aquamarine;
                border-bottom: 4px solid $aquamarine;
                display:block;
                min-width:100% !important;
                width: auto !important;
                overflow: visible;
                height: auto;
                position: absolute;
                top: 48px;
              }
            }
          }
        }
        .dropdown-menu{
          min-width:0%;
          width: 0;
          height: 0px;
          display: block !important;
          overflow: hidden;
          padding: 0;
          position: absolute;
          > li:first-child {
            font-family: "Roboto-Bold";
            color: $aquamarine;
            font-size: 1.5rem;
            padding: 1rem 1.5rem 1rem;
          }
          a {
            display: block;
            color: $black;
            &:after {
              content: "";
              height: 2px;
              width: 0;
              display: block;
              transition: all 0.6s ease-out;
            }
            &:hover{
              background:#F1FFFF !important;
              &:after {
                width: 100%;
                background: $orange;
                transition: all 0.6s ease-out;
              }  
            }
            &.active{
              color: $black;
              background:#F1FFFF !important;
              &:after {
                width: 100%;
                background: $orange;
                transition: all 0.6s ease-out;
              }  
            }
          }
        }
      }
      .navbar-toggle .icon-bar {
        color: #666;
        background: #666;
      }
    }
  }
/** END HEADER **/
