
.datavisualisation-page {
  .section--page{
    margin-bottom: 2rem;
  }
  h2{
    font-family: "OpenSans-bold", sans-serif;
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .subtitle--section{
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .datavisualisation-page{
    &.de, &.sv{
      .card--grid{
        flex-wrap: wrap;
        .card--item{
          width: 50%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .datavisualisation-page{
    .card--grid{
      flex-direction: column;
      .card--item{
        width: 100%;
        &.min-height{
          .title--card{
            min-height: 10rem;
          }
        }
        a{
          background-position: 2rem center;
          background-size: 12rem !important;
          &.icon--card{
            height:14rem;
            &:hover{
              background-position: 2rem center !important;
            }
          }
          span{
            position: absolute;
            bottom:45%;
            left: auto;
            min-height:auto;
            width: 70%;
            padding: 0 1.3rem;
            display: block;
            right: 0;
            text-align: left;
          }
        }
      }
    }
    &.de, &.sv{
      .card--grid{
        .card--item{
          width: 100%;
        }
      }
    }
  } 
}

@media (max-width: 475px) {
  .datavisualisation-page{
    .card--grid{
      .card--item{
        &.min-height{
          .title--card{
            min-height: 10rem;
          }
        }
        a{
          background-size: 9rem !important;
          background-position: 1rem center !important;
          &.icon--card{
            height:10rem;
            &:hover{
              background-position: 1rem center !important;
            }
          }
          span{
            position: absolute;
            bottom: auto;
            top: 30%;
            left: auto;
            min-height: auto;
            width: 60%;
            padding: 0 1.3rem;
            display: block;
            right: 0;
            text-align: left;
            height: auto;
          }
        }
      }
    }
    &.de{
      .card--grid{
        .card--item{
          width: 100%;
          &.min-height{
            .title--card{
              min-height: 14rem;
            }
          }
          a{
            background-position: 1rem 1rem !important;
            &.icon--card{
              height: 14rem;
              &:hover{
                background-position: 1rem 1rem !important;
              }
            }
            span{
              position: absolute;
              bottom: auto;
              top: 9rem;
              left: auto;
              min-height: auto;
              width: 100%;
              padding: 0 1.3rem;
              display: block;
              right: 0;
              text-align: left;
              height: auto;
            }
          }
        }
      }
    }
    .btn-main-color{
      width: 90%;
    }
  } 
}

/*** EXPORT PNG CLASS **/
.survey--map--block{
  &::after {
    content: "";
    clear: both;
    display: table;
  }
  #svg2image-map{
    width:1024px !important;
    height: auto !important;
    margin:auto !important;
    padding: 5rem !important;
  }
  #svg2image{
    width:1024px !important;
    height:auto !important;
    margin:auto !important;
    padding:0rem !important;
  }
}


.datavisualisation-page{
  .card--item, .card--item.disabled{
    &:hover{
      .osh-management-icon{
        background:$aquamarine-light url(../style/img/osh-management-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .psychosocial-risk-icon{
        background:$aquamarine-light url(../style/img/psychosocial-risk-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;  
      }
      .drivers-barriers-icon{
        background:$aquamarine-light url(../style/img/drivers-barriers-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .workers-participation-icon{
        background:$aquamarine-light url(../style/img/workers-participation-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .european-maps-icon{
        background:$aquamarine-light url(../style/img/european-maps-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .eu-bar-chart-icon{
        background:$aquamarine-light url(../style/img/eu-bar-chart-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset; 
      }
      .national-bar-chart-icon{
        background:$aquamarine-light url(../style/img/national-bar-chart-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .national-comparision-icon{
        background:$aquamarine-light url(../style/img/national-comparision-hover.png) center 3rem no-repeat;
        -webkit-filter: unset;
        filter: unset;
      }
      .pie-chart-icon{
         background:$aquamarine-light url(../style/img/pie-chart-hover.png) center 3rem no-repeat; 
        -webkit-filter: unset;
        filter: unset;
      }
    }
    &.tablet{
      .osh-management-icon{
        background: url(../style/img/osh-management.png) center 3rem no-repeat;
      }
      .psychosocial-risk-icon{
        background: url(../style/img/psychosocial-risk.png) center 3rem no-repeat;
      }
      .drivers-barriers-icon{
        background: url(../style/img/drivers-barriers.png) center 3rem no-repeat;
      }
      .workers-participation-icon{
        background: url(../style/img/workers-participation.png) center 3rem no-repeat;
      }
      .european-maps-icon{
        background: url(../style/img/european-maps.png) center 3rem no-repeat;
      }
      .eu-bar-chart-icon{
        background: url(../style/img/eu-bar-chart.png) center 3rem no-repeat;
      }
      .national-bar-chart-icon{
        background: url(../style/img/national-bar-chart.png) center 3rem no-repeat;
      }
      .national-comparision-icon{
        background: url(../style/img/national-comparision.png) center 3rem no-repeat;
      }
      .pie-chart-icon{
        background: url(../style/img/pie-chart.png) center 3rem no-repeat;
      }
    }
  }    
}

