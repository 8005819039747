@font-face {
  font-family: 'Oswald-Light';
  src: url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    /*	url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.woff2') format('woff2'),  Modern Browsers */
      url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.woff') format('woff'), /* Modern Browsers */
      url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.ttf') format('truetype'); /* Safari, Android, iOS */
    /* 	url('./fonts/Oswald/Oswald-Light/fonts/Oswald-Light.svg') format('svg');  Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
