@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Regular.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url('./fonts/Roboto/Roboto-Medium.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('./fonts/Roboto/Roboto-Bold.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Black';
    src: url('./fonts/Roboto/Roboto-Black.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-thin';
    src: url('./fonts/Roboto/Roboto-Thin.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Italic';
    src: url('./fonts/Roboto/Roboto-Italic.ttf'); 
    font-weight: normal;
    font-style: normal;
}
  
  