.methodology{
	a{
		color:$aquamarine;
	}
	h1{
		color: $orange;
		text-align: center;
	}
	h2{
		color:$aquamarine;
	}
	h3{
		color:$aquamarine;
		margin-top: 4rem;
	}
	h4{
		color:$aquamarine;
		font-family: "Oswald-light", sans-serif;
		margin: 3rem 0 1rem;
	}
	.table{
		tr:nth-child(1){
			background: #f2f2f2;
			th{
				text-align: center;
			}
		}
		tr:nth-child(1).center-text{
			th{
				text-align: center;
			}
			th:nth-child(1){
				text-align: left;
			}
		}
	}
	th.center-text{
	text-align: center;
	}
	.data-table{
		td{
		text-align: center;
		}
	}
	.table2{
		tr:nth-child(1){
			th{
			text-align: center;
			background: #f2f2f2;
			}
			th:nth-child(1){
			text-align: left;
			}
		}
		tr:nth-child(2){
			th{
				text-align: center;
				background: #f2f2f2;
				white-space: nowrap;
			}
		}
		tr{
			td{
				text-align: center;
				&:nth-child(1){
					text-align: left;	
				}
			}
		}
	}
	.table3{
		tr:nth-child(1){
			th:nth-child(1){
				text-align: left;
			}
		}
	}
}

@media only screen 
and (max-width : 425px){
	.table {
	    width: 100%;
	    max-width: 100%;
	    margin-bottom: 18px;
	    margin: auto;
	    border-collapse: collapse;
	    overflow-x: auto;
	    display: block;
	    width: fit-content;
	    max-width: 100%;
	    box-shadow: 0 0 14px 1px rgba(0, 0, 0, .1);
	}
}