/** GENERAL TAGS  **/
  html{
    font-size: 10px;
  }
  body{
    font-family: $font-body;
    font-size:$font-size-body;
    color: $gray-base;
    padding-top:22rem;
    overflow-x: hidden;
    &.mobile-device{
      position: relative;
    }
  }
  .ccc-tt-ds .ccc-tt-dimValue .ccc-tt-valuePct{
    display: none !important;
  }
  body.hasCookies{
    //padding-top: 9rem;
    .ilo-header #containerMenu{
      position: relative;
    }
    /*
    #google_translate_element_wrapper{
      top: 4rem;
      position: relative;
      width: auto;
      float: right;
      right:0;
    }
    span.google_translate_span {
      width: 135px;
      left: 0px !important;
    }
    span.gtranslator-info {
      right: 0em !important;
    }
    .gtranslator-msg {
      top: 199px;
    }
    */
  }
  *:focus{
    outline:none !important;
    background: transparent;
  }
  button[disabled], html input[disabled] {
    cursor: default;
    pointer-events: none;
  }
  .section--page{
    padding: 4rem 0 2rem;
  }
  h1, h2, h3, h4 {
    font-family:$font-header;
    margin-top:2rem;
    margin-bottom:2rem;
  }
  h2{
    font-size: 3.6rem;
  }
  h4{
    font-family:$font-body-bold;
    font-size: 2.6rem;
  }
  a{
    text-decoration: none;
    -webkit-transition: background 0.8s ease-out;
    -moz-transition: background 0.8s ease-out;
    -o-transition: background 0.8s ease-out;
    transition: background 0.8s ease-out;
    &:hover{
      text-decoration: none;
      -webkit-transition: background 0.8s ease-out;
      -moz-transition: background 0.8s ease-out;
      -o-transition: background 0.8s ease-out;
      transition: background 0.8s ease-out;
    }
    &.main-color{
      color: $aquamarine;
    }
    &.second-color{
      color: $orange;
    }
    &.external{
      margin-right: 0.5em;
      word-wrap: break-word;
      &:after{
        background: url(../style/img/external_link.png) transparent right 30% no-repeat;
        margin-left:0em;
        padding-right: 0;
        width: 15px;
        height: 15px;
        display: inline-block;
        content: " ";
      }
    }
    &[target="_blank"], 
    &[target="blank"]{
      margin-right: 0.5em;
      word-wrap: break-word;
      display:inline-block;
      &:after{
        background: url(../style/img/external_link.png) transparent right 30% no-repeat;
        margin-left:0em;
        padding-right: 0;
        width: 15px;
        height: 15px;
        display: inline-block;
        content: " ";
      }
    }  
  }
  .affix {
    position: fixed;
    top: 132px;
    width: 100%;
    background: #FFF;
  }
  .outleft{
    position: absolute;
    top: -500rem;
    left: -500rem;
  }
/** END DGENERAL TAGS  **/


/** CUSTOM CLASS  **/

  .outleft{
    position: absolute;
    top: -9999px;
    left:-9999px;
  }
  .logos-mobile {
    display: none;
  }
  .hide-header{
    position: fixed;
    top: -23rem !important;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    &.affix, &.affix-top{
      top: -25rem;
      background: $white;
      width: 100%;
    }
  }
  .show-header{
    position: fixed;
    top:0rem;
    -webkit-transition: all 0.8s; 
    transition: all 0.8s;
    &.affix, &.affix-top{
      top:9rem;
      background: $white;
      width: 100%;
    }
  }
  .show-mobile{
    display: none;
  }
  .nopadding{
    padding: 0;
  }
  .tablet-show{
    display: none;
  }
  .vertical-nopadding{
    padding-top: 0;
    padding-bottom: 0;
  }
  .horizontal-nopadding{
    padding-top: 0;
    padding-bottom: 0;
  }
  /** ALIGN CLASS  **/
    .center--block{
      margin: auto;
    }
    .left-text{
      text-align: left;
    }
    .center-text{
      text-align: center;
    }
    .right-text{
      text-align: right;
    }
  /** END ALIGN CLASS  **/

  /** BUTTONS ELEMENT AND BLOCK  **/  
    .btn--block{
       padding: 1.4rem 2rem;
    }
    .btn--block-full{
      padding: 1.4rem 0rem;
      a{
        font-weight: bold;
        position: relative;
        white-space: normal;
        &:after{
          font-weight:normal;
          content: "\f105";
          font-family: FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 2.6rem;
          position: absolute;
          top: 0;
          right: 0;
          padding: 0.5rem 2rem;
          color: $white;
        }
      }
    }
    .btn--block-filter{
      padding: 0rem;
      margin: 0;
      border: 0;
      a{
        position: relative;
        padding: 0.5rem;
        text-align: center;
        cursor: pointer;
      }
    }
    .btn-default{
      position: relative;
      padding: 0.8rem 7rem;
      margin:2rem 0;
      font-weight: bold;
      letter-spacing: 0.3rem;
      white-space: nowrap;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: none;
      border: 1px solid $aquamarine;
      display: inline-block;
      > span{
        position: relative;
        width: 100%;
        display: block;
        z-index: 2;
      }
      &:before{
        transition: all 0.4s cubic-bezier(.45,.39,.67,.6);
        content: '';
        width: 0%;
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &:hover{
        text-decoration: none;
        color: $white;
        &::before{
          width: 100%;
        }
      }
    }
    .datavisualisation-page{
      .btn-default{
        &:hover{
          opacity: 0.8;
          &::before{
            width: 0%;
          }
        }
      }
    }
    .btn-main-color{
      border: 1px solid $aquamarine;
      color: $aquamarine;
      &:hover{
        border: 1px solid $aquamarine;
        span{
          color: $white;
          background: transparent;
        }
        &::before{
          background:$aquamarine;
        }
      }
    }
    .datavisualisation-page{
      .btn-main-color{
        background: $aquamarine;
        color: $white;
        //pointer-events: none;
        &[disabled] {
          opacity: 0.4;
          border: 1px dotted #57575A;
          color: #57575A;
          background:transparent;
        }
      }
    }
    .btn-orange{
      border: 1px solid $orange;
      color: $orange;
      &:hover{
        background: $orange-light;
        border: 1px solid $orange;
        span{
          color: $white;
          background: transparent;
        }
        &::before{
        background:$orange;
        }
      }
    }
    .btn-full{
      display: block;
      width: 100%;
    }
    .btn--card{
      font-weight: bold;
      position: relative;
      &:after{
        content: "\f105";
        font-family: FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 2.6rem;
        position: relative;
        top: 0.3rem;
        left: 0.5rem;
        color: $orange;
      }
    }
  /** END BUTTONS ELEMENT AND BLOCK  **/

  /** FORM ELEMENTS  **/
    select::-ms-expand {
        display: none;
    }
    .select-default{
      position: relative;
      select{
        width: 100%;
        padding: 1rem 2rem;
        border-radius: 4px;
        border:1px solid $aquamarine;
        color:$aquamarine;
        position: relative;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; 
      }
      &:after {
        content:"\f107";
        font-family: FontAwesome;
        font-weight: normal;
        font-size: 2rem;
        color:$aquamarine;
        right: 11px;
        top: 0;
        height: auto;
        padding:1rem;
        position: absolute;
        pointer-events: none;
      }
    }
  /** END FORM ELEMENTS  **/

  /** CARDS TYPE **/
    .card--grid{
      display: flex;
      flex-wrap: nowrap;
      justify-content:space-between;
      list-style-type: none;
      padding: 0;
      position: relative;
      .card--item{
        width: 25%;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        margin: 0;
        a{
          font-size: 1.4rem;
          font-family: 'OpenSans-bold';
          text-transform: uppercase;
          color: $orange;
          text-align: center;
          letter-spacing: -0.5px;
          &.icon--card{
            height: 24rem;
            width: auto;
            margin: 0rem;
            padding: 2rem;
            background-color: #FDEDCC;
            background-size: auto;
            display: block;
            position: relative;
            &:hover{
              color: $aquamarine;
              background-color:$aquamarine-light;
            }
          }
          span{
            position: absolute;
            bottom: 0;
            left:0;
            min-height: 6rem;
            width: 100%;
            padding: 0 1.3rem;
            display: block;
          }
        }
        p{
          font-size: 1.2rem;
          color: $gray-light;
          margin: 0.5rem 0;
        }
        &.min-height{
          .title--card{
            min-height: 10rem;
          }
        }
        &:hover{
          cursor: pointer;
          .icon--card{
            color: $aquamarine;
            background-color:$aquamarine-light;
          }
          p{
            color: $black;
            cursor: pointer;
          }
        }
        &.selected{
          a{
            outline: 0.5rem solid #00a1a3 !important;
          }
          p{
            color: $black;
          }          
        }
        &.disabled{
          border: 0 !important;
          cursor: default !important;          
          a{
            background-color: rgba(177, 179, 180,0.2);
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
          }
          .text-center{
            color: rgba(177, 179, 180,1);
          }
          &:hover{
            .icon--card{
              color: $aquamarine;
              background-color:$aquamarine-light;
            }
            p{
              color: $black;
              cursor: pointer;
            }
          }         
        }
        &.tablet{
          a{
            color: $orange !important;
            background-color: #FDEDCC !important;           
          }
        }
      }
      .card--block--rounded{
        border-radius: 46px 0;
        padding:3rem 2rem;
        margin:0 1%;
        background: $white !important;
        position: relative;
        display: flex;
        flex-direction: column;
        .icon--card{
          height: 20rem;
          width: 100%;
          display: block;
          cursor: pointer;
          &:hover{
            opacity:0.6;
          }
        }
        .title--card{
          font-size: 2.8rem;
          display: block;
          margin: 0;
          padding: 2rem 0;
          a{
            color:$orange;
            cursor: pointer;
            &:hover{
              color:$aquamarine;
            }
          }
        }
        .btn--card{
          font-weight: bold;
          position: relative;
          cursor: pointer;
          &:hover, &:focus{
            color:$orange!important;
          }
          &:after{
            content: "\f105";
            font-family: FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 2.6rem;
            position: relative;
            top: 0.3rem;
            left: 0.5rem;
            color: $orange;
          }
        }
        .btn-group-card{
          margin-top:auto;
          label{
            color: $aquamarine;
            margin-bottom: 1.2rem
          }
        }
      }
      .card--block--chart{
        background: $gray-lighter;
        padding: 1rem 2rem 3rem 2rem;
        border-radius: 46px 0;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        .chart--submenu{
          list-style: none;
          display: flex;
          justify-content: flex-end;
          position: relative;
          top:0rem;
          right: -2rem;
          > li{
            > a{
              width: 3.5rem;
              height: 2.7rem;
              display: block;
              color: $gray-base;
            }
            &:nth-child(1) a:after{
              content:"\f00e";
              font-family: FontAwesome;
            }
            &:nth-child(2) a:after{
              content: "\f019";
              font-family: FontAwesome;
            }
            &:nth-child(3) a:after{
              content: "\f107";
              font-family: FontAwesome;
            }
          }
        }
        .title--card{
          font-size: 2.8rem;
          margin-top: 0;
          text-align: left;
          min-height: 7rem;
        }
        .chart--block{
          //margin-top:auto; 
          img{
            width: 100%;
          }
        }
      }
    }
    .column--grid--block{
      display: flex;
      flex-wrap: nowrap;
      justify-content:space-between;
      flex-direction: row;
      .column--item{
        padding: 0 5rem;
        width: 50%;
        h2{
          font-size: 2.4rem;
        }
        &.first{
          border-right: 2px solid $aquamarine-light;
          &.full{
            border-right: 0;
            width: 100%;
          }
          .columm--item--content{

          }
        }
        .flags--wrapper{
          outline: 1px solid #CCC;
        }
      }
    }

  /** END CARDS TYPE **/ 

  /** COLORS CLASS **/
    .main-color{
      color: $aquamarine;
    }
    .second-color{
      color: $orange;
    }
    .background-main{
      background: $aquamarine;
    }
    .background-main-light{
      background: $aquamarine-light;
      .title--section{
        color: $aquamarine;
        a{
          color: $aquamarine;
          &:hover{
            text-decoration:none;
            color: $orange;
          }
        }
      }
    }
    .background-orange-light{
      background: $orange-light;
      .title--section{
        color: $orange;
        a{
          color: $orange;
          &:hover{
            text-decoration:none;
            color: $aquamarine;
          }
        }
      }
    }
    .background-gray{
      background: $gray-lighter;
    }
    .background-white{
      background: $white;
    }
  /** END COLORS CLASS  **/

  /** WIDTH CARDS  **/
    .w2{
      .card--block{
        width:50%;
      }
      .card--block--rounded{
        width:48%;
      }
      .card--block--chart{
        width:48%;
      }
    }
    .w3{
      .card--block{
        width: 33.333%;   
      }
      .card--block--rounded{
        width:31%;
      }
      .card--block--chart{
        width:31%;
      }
    }
    .w4{
      .card--block{
        width:25%;
      } 
      .card--block--rounded{
        width:23%;
      }
      .card--block--chart{
        width:23%;
      }
    }
    .w5{
      .card--block{
        width:20%;    
      }
      .card--block--rounded{
        width:18%;
      }
      .card--block--chart{
        width:18%;
      }
    }
  /** END WIDTH CARDS  **/

/** END CUSTOM CLASS  **/



/** ELEMENTS  **/
  .line{
    width: 100%;
    height: 0.2rem;
  }
  .relative-top{
    position: relative;
    top: -0.7rem;
  }
  .title-section{
    font-family: 'Oswald';
    font-size: 3.6rem;
    margin: 2rem 0 6rem;
  }
  .breadcrumbs--social--network{
    position: relative;

    padding: 0.5rem 2rem; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    .breadcrumbs{
      .path{
        margin: 0;
        padding: 0;
        font-size: 1.4rem;
        color: $aquamarine;
        a{
          border-bottom: 1px dotted $orange;
          color: $aquamarine;
          font-family: "OpenSans-bold";
          &:hover, &:focus{
            color: $orange!important;
          }
        }
        i{
          color: $orange;
          font-size: 1.8rem;
          padding: 0 5px;
        }
        span {
          font-weight: normal;
          &.current-page{
            font-weight: bold;
          }
        }
      }    
    }
    .social--network--nav{
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin-right: .7rem;
      label{
        padding-left: 5px;
        padding-right: 5px;
        font-size: 1rem;
        font-weight: 400;
      }
      ul{
        padding: 0 .8rem 0 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        li{
          padding: 0;
          width: 3.2rem;
          button{
            cursor: pointer;
            svg {
              path {
                fill:$aquamarine !important;
              }              
            }
          }
          a{
            cursor: pointer;
            &:hover, &:focus{
              color:$orange!important;
            }
          }
        }
      }
    }
  }
  .advice--block{
    position: relative;
    top: -0.7rem;
    padding: 6rem 0;
    .title--section{
      font-family: 'Oswald';
      font-size: 3.6rem;
      padding: 1rem 21rem 1rem 21rem;
      text-align: center;
    }
  }
  .advice--icon--block{
    position: relative;
    top: -0.7rem;
    padding: 6rem 0;
    .title--section{
      font-family: 'Oswald';
      font-size: 3.6rem;
      padding: 0;
      text-align: left;
    }
  }
  .icon--advice{
    height: 20rem;    
  }
  .breadcrumbs--block{
    .breadcrumbs--list{
      li{
        display: inline-block;
        margin-right: 1rem;
        &:after{
          content: "\f105";
          font-family: FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 2.6rem;
          position: relative;
          top: 0.3rem;
          left: 0.5rem;
          color: $orange;
        } 
        &:last-child:after{
          content: "";
        }
        a{
          color: $aquamarine;
        }
        &.active{
          font-weight: bold;
        }
      }

    }
  }
  .social--network--nav{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 1rem; 
    label{
      padding-left: 5px;
      padding-right: 5px;
      font-size: 1rem;
      font-weight: normal;
    }
    ul{
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around; 
      li{
        padding:0 0.8rem;
        a{
          cursor: pointer;
          &:hover, &:focus{
            color:$orange!important;
          }
        }
      }
    }
  }
  .compare--block{
    .compare--list{
      display: flex;
      justify-content: space-between;
      list-style: none;
      li{
        width: 50%;
        label{          
          font-family: 'OpenSans-Light';
          text-transform: uppercase;
          font-weight: normal;
          letter-spacing: -0.2rem;
          &:after{
            content: "\f105";
            font-family: FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 2.6rem;
            position: relative;
            top: -0.2rem;
            left: 0rem;
            padding: 2rem;
            color: $gray-base;
          }
        }
        select{
          font-family: 'OpenSans-bold';
          text-transform: uppercase;
          font-size: 2.6rem;
          border: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;   
          option{
            font-family: 'OpenSans';
            font-size: 1.4rem;  
            &:checked{
            
            }
          }
        }
        &:nth-child(1){
          select{
            color: $orange;
          }
        }
        &:nth-child(2){
          select{
            color: $aquamarine;
          }
        }
      }
    }
  }

  .submenu--block{
    padding: 1rem 0 0.5rem;
    label{
      display: none;
    }
    .submenu--items--wrapper{
      padding:0; 
      display: flex;
      justify-content:flex-start;
      list-style: none;
      flex-direction: row;
      flex-wrap: nowrap;
      li{
        width: 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a{          
          font-family: "Oswald", sans-serif;
          font-size: 1.8rem;
          color: $gray-light;
          font-weight: normal;
          padding:0;
          border-bottom: 4px solid transparent;
          display: inline-block;
          cursor: pointer;
          &.active, &:hover, &:focus, &:visited {
            border-bottom: 4px solid $orange;
            text-decoration: none;
            display: inline-block;
            //color: $aquamarine;
          }
        }
        &.active{
          a, a.active, a:hover, a:focus, a:visited {
            color: $aquamarine;
          }
        }
      }
    }
  }

  .submenu--level2{
    padding: 1rem 0;
    .submenu--items--wrapper{
      padding:0; 
      display: flex;
      justify-content:flex-start;
      list-style: none;
      flex-direction: row;
      flex-wrap: nowrap;
      li{
        width: auto;  
        text-align: center;
        a{          
          font-family: "OpenSans-bold", sans-serif;
          font-size: 2rem;
          padding:1rem 2.6rem !important; 
          color: $gray-light;
          font-weight: normal;
          padding:0;
          &:hover{
            //background: $orange;
            //color:white;      
            text-decoration: none;
            outline: 1px solid $orange;
            //display: inline-block;
            //color: $aquamarine;
          }
          &.active, &:active, &:focus{
            color: $aquamarine;
            padding:1rem 2.6rem !important; 
            background: $orange;
            color:white;   
          }
          &:visited{
            text-decoration: none;
          }
        }
      }
    }
  }


  .level3--wrapper{
    border-bottom: 1px solid $gray-light;
    .submenu--level3{
      margin-top: 1.5rem;
      padding:0rem;
      .submenu--items--wrapper{
        padding:0; 
        margin: 1rem 0 0 0;
        display: flex;
        justify-content:flex-start;
        list-style: none;
        flex-direction: row;
        flex-wrap: nowrap;
        li{
          width: auto;
          text-align: center;
          a{          
            font-family: "OpenSans", sans-serif;
            font-size: 2.5rem;
            padding: 0.5rem 2.6rem 0 !important;
            color: $gray-light;
            font-weight: normal;
            padding:0;
            border-bottom: 6px solid transparent;
            display: inline-block;
            &.active, &:hover, &:focus, &:visited {
              border-bottom: 6px solid $orange;
              text-decoration: none;
              display: inline-block;
              //color: $aquamarine;
            }
          }
          &.active{
            a, a.active, a:hover, a:focus, a:visited {
              color: $aquamarine;
              font-family: "OpenSans-Bold", sans-serif;
            }
          }
        }
        &.de, &.bg, &.da, &.et{
          li{
            a{
              font-size: 2rem;
              padding: 0.5rem 1.2rem 0 !important;
            }
          }
        }
        &.lv, &.lt, &.mt, &.pl, &.pt, &.sl, &.sv{
          li{
            a{
              font-size: 2.4rem;
              padding: 0.5rem 2rem 0 !important;
            }
          }
        }
        &.es, &.fr{
          li{
            a{
              font-size: 2.4rem;
              padding: 0.5rem 1.6rem 0 !important;
            }
          }
        }
      }
    }    
  }

  .filter--indicator--block{
    .indicators--group{
      display: flex;
      justify-content: space-between;
      list-style: none;
      li{
        width: 50%;
        padding: 1rem 4rem 1rem 0;
        position: relative;
        label{          
          font-family: 'OpenSans-Light';
          text-transform: uppercase;
          font-weight: normal;
          letter-spacing: 0.2rem;
          display: block;
        }
        &:after {
          content: "\f107";
          font-family: FontAwesome;
          font-weight: normal;
          font-size: 2rem;
          color: #00A0A4;
          right: 4rem;
          top: 42%;
          height: auto;
          padding: 1rem;
          position: absolute;
          pointer-events: none;
        }
        select{
          width: 100%;
          margin: 1rem 0;
          padding: 1rem 2rem;
          border-radius: 4px;
          border:1px solid $aquamarine;
          color:$aquamarine;
          position: relative;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          option{
            &:checked{
            
            }
          }
        }
        &:nth-child(1){
          select{

          }
        }
        &:nth-child(2){
          select{

          }
        }
      }
    }
  }
  .carousel--home{
      position: relative;
      // min-height: 32rem;
      min-height: 33rem;
    .carousel-item {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 32rem;
    }
    .carousel-caption {
      left: 0% !important;
      right: 0% !important;
      padding: 0 !important;
      top: 0;
      color: #666;
      text-align: left;
      width: 100%;
    }
    .carousel-inner {
      width: 88%;
      padding: 0;
      margin: 0px auto;
      // min-height: 32rem;
      min-height: 33rem;
      position: relative;
      overflow: hidden;
      ul{
        list-style-type: disc;
        padding: 0 0 0 2rem;
        margin: 0;
        li{
          padding:0.4rem 0;
        }
      }
    }
    .carousel-indicators{
      bottom: -2rem;
      margin-bottom: 0;
      li{
        background: $gray-icon;
        width: 1rem;
        height: 1rem;
        margin: 0 0.6rem;
        border: 0;
        border-radius: 6rem;
        &.active{
          width: 1rem;
          height: 1rem;
          background: $orange;
        }       
      }
    }
    .carousel-control-prev, 
    .carousel-control-next{
      display: none;
    }
    .carousel-control{
      background: transparent;
      opacity: 1;
      width: 10%;
      cursor: pointer;
      &:hover, &:focus {
        filter: alpha(opacity=100);
        opacity: 1;
        color: $orange;
      }
      i{
        text-shadow: none;
        font-size: 4rem;
        position: absolute;       
        top: 40%;
        &.fa-angle-left{
          left: 0;
        }
        &.fa-angle-right{
          right: 0;
        }       
      }
    }
  }
/** END ELEMENTS  **/




.grid-2{
  padding: 3rem 3rem 4rem;
  .col-img{
    a, div{
      display: block;
      img{
        width: 100%;
        &:hover{
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          -webkit-transition: filter 0.8s; /* Safari */
          transition: filter 0.8s;
        }
        &.no-hover:hover{
          -webkit-filter:none; /* Safari 6.0 - 9.0 */
          filter: none;
        }
      }     
    }
  }
  .col-content{
    .title--section{
      font-size: 1.8rem;
      font-weight: bold;
      ul{
        font-size: 1.6rem;
        font-weight: normal; 
      }
    }
    h2.title--section{
      font-size: 3rem;
      padding-top: 0;
      margin-top: 0;
    }
    .carousel--home{

    }
  }
}


.see-more-special{
  text-align: center;
  margin: 3rem 0 1rem;
  background: url(../style/img/back-button.png);
  position: relative;
  //z-index: -1;
  a{
    text-decoration:none;
    color: $aquamarine;
    border: 1px solid $orange;
    padding: 0.5rem;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0.5rem 2rem; 
    background: $white;
    position: relative;
    &:hover, &:focus{
      background: $orange!important;
      color: $white!important;
      i{
        background: $aquamarine;
      }  
    }
    i{
      color:$white;
      background: $orange;
      position: absolute;
      top: -8px;
      left: 45.5%;
      padding: 0px;
      border-radius: 16px;
      width: 12px;
      height: 12px;
    }       
  }
}
.see-more{
  margin: 2rem 0;
  text-align: right;
  a.see-more{
    text-decoration: none;
    color: $aquamarine;
    padding: 0.5rem 0;
    position: relative;
    font-family:'OpenSans-bold';
    cursor: pointer;
    &:hover, &:focus{
      color:$orange;
    }
    &:after{
      content: "\f107";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 0.5rem;
    }
  }
  a.see-less{
    text-decoration: none;
    color: $aquamarine;
    padding: 0.5rem 0;
    position: relative;
    font-family:'OpenSans-bold';
    cursor: pointer;
    &:hover, &:focus{
      color:$orange;
    }
    &:after{
      content: "\f106";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 0.5rem;
    }
  }
}



[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.three-points-vertical{
  //background: url(../style/img/three-point-vertical.svg) no-repeat;
  background-size: 12px 27px;
  width: 27px;
  height: 27px;
  display: inline-block;
  opacity: 1;
  position: relative;
  top: -3px;
}


/****** GENERAL CHARTS *****/

/**** HEIGHT SIZE ***/
  .heighth31 {
    h3 {
      min-height: 6em !important;
    }
  }

  .heighth32 {
    h3 {
      min-height: 2.5em;
    }
  }
  .heighth33 {
    h3 {
      min-height: 3.8em;
    }
  }
  .heighth34 {
    h3 {
      min-height: 4.5em;
    }
  }
  .heighth35 {
    h3 {
      min-height: 6.5em;
    }
  }
  .heighth36 {
     h3 {
      min-height: 5.5em;
    }
  }

  .heightAuto {
    h3 {
      min-height: auto !important;
    }
  }
/**** END HEIGHT SIZE ***/

/**** MODAL WINDOW ****/
  .dvt-modal {
    display: block;
    background:$white;
    width: 70%;
    margin: auto;
    height: 90%;
    border-radius: 6px;
    .modal-wrapper {
      height: auto;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #666;
      }
      .modal-body {
        height: 100%;
        .card--block--chart--wrapper {
          .chart--submenu{
            list-style: none;
            display: block;
            justify-content: unset;
            position: relative;
            width: 80%;
            margin: auto;
            padding: 0;
            > li{
              float: right;
              > a{
                width: 3.5rem;
                height: 2.7rem;
                display: block;
                color: $gray-base;
                &.download-button:after{
                  content: "\f019";
                  font-family: FontAwesome;
                }
              }
            }
          }
          .export-button-modal{
            a{
              padding: 0.8rem 1rem;
              border-radius: 0.3rem;
              background: #f2f2f2;
              color: #000;
              font-size: 1.4rem;
              display: block;
              overflow-y:hidden; 
              &:hover{
                color:$white;
                background: $aquamarine;
                .fa-arrow-down{
                  top:0rem;
                  -webkit-transition: all 0.8s; 
                  transition: all 0.8s;
                }
                .fa-picture-o{
                  top:3rem;
                  -webkit-transition: all 0.8s; 
                  transition: all 0.8s;
                }
              }
              .fa-arrow-down{
                position: relative;
                top: -3rem;
                left: 3px;
                -webkit-transition: all 0.8s; 
                transition: all 0.8s;
              }
              .fa-picture-o{
                position: relative;
                top:0rem;
                left:-1rem;
                -webkit-transition: all 0.8s; 
                transition: all 0.8s;
              }
            }
          }
          .title--card{
            font-size: 2.8rem;
            margin-top: 0;
            text-align: left;
          } 
          .chart--wrapper{
            width: 80%;
            height: auto;
            margin: 10vh auto;
            svg{
              text{
                font-size: 14px !important;
              }
            }
          }      
        }
      }
    }
    .dvt-chart {
      height: 100% !important;
      width: 100%;
      overflow: hidden;
      border-radius: .5em;
      margin: 0;
      position: relative;
      left: 0%;
      top: 0%;

      h3{
        font-size:2rem !important ;
        min-height: auto !important;
      }
      .legend-info{
        float: left;
        background: $white;
        width: 100%;
        padding: 0 30px;
        color: #000;
        text-align: center;
        font-size: 1.4rem;
      }
    }
  }





  /**** COKKIES DIWSCLAIMER ****/
  #cookiesConsent {
    background: $backgroundCookies;
    width: 100%;
    padding:1.5em;
    overflow: auto;
    z-index:1000001;
    top: 0;
    position: fixed;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .text {
      float:left;
      flex: 1
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      > a{
        order: 3;
      }
      > .accept{
        order: 1;
      }
      > .decline{
        order: 2;
      }
    }
    button {
      color:$white;
      background: url('../style/img/buttomArrow.png') no-repeat 95% 53% $aquamarine;
      font-family: "Oswald", sans-serif;
      padding:.7em 1.5em;
      border:0;
      float:right;
      margin:0 .5em;
    }
  }
  /*Width disclaimer*/
  #widthDisclaimer {
    background: #cbe4e5;
    width: 100%;
    padding: 20px 20px;
    font-size: 1.4rem;
    overflow: auto;
    z-index: 100001;
    position: fixed;
    top: 0;
    color: #000;
    .text {
      width:100%;
      text-align: center;
    }
    .buttons {

    }
    button {
      color: $white;
      background: url(../style/img/buttomArrow.png) no-repeat 90% 56% $aquamarine;
      font-family: "Oswald", sans-serif;
      padding: 7px 25px 7px 15px;
      border: 0;
      float: none;
      margin: 20px 35%;
      width: 30%;
    }
  }
/**** END MODAL WINDOW ****/

/** SITE MAP **/
  .site--map--block{
    h2{
      margin: 3rem 0 2rem;
      color: $gray-base;
    }
    h3{
      margin: 2rem 0 1rem;
      color: #333;
    }
    a{
      padding: 1rem 0.6rem !important;
      display: block;
    }
    > ul{
      > li{
        list-style-type: none;
        &:nth-child(1){
          > a:before{
            content: '';
          }          
        }      
        .sr-only{
          position: static;
        }
        > span{
          border-bottom: 1px dotted  $gray-base;
          padding: 10px 6px !important;
          display: block;
          &:before{
            content: "\f105";
            display: inline-block;
            font-family:'FontAwesome';
            font-size: 1.2rem;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            right: 5px;
            top: -2px;
          }
        }
        > a{
          border-bottom: 1px dotted  $gray-base;
          &:hover, &:focus{
            background:#f9f9f9!important;
            text-decoration: none!important;
          }
          &:before{
            content: "\f105";
            display: inline-block;
            font-family:'FontAwesome';
            font-size: 1.2rem;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            right: 5px;
            top: -2px;
          }
        }
        > ul{
          list-style-type: none;
          padding: 0 0 0 2rem;
          > li{
            > a{
              border-bottom: 0;
              cursor: pointer;
               &:before{
                content:'';
              }
            }
          }
          > li:last-child{
            border-bottom:none;
          }
        }
      }
    }
  }
/** LEGAL NOTICE  **/
  .legal--notice--block{
    margin: 0rem auto 5rem;
    h2{
      margin: 2rem 0;
    }
    table {
      border-collapse: collapse;
      width: 80%;
      margin: 5rem auto;
      th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid $aquamarine;
      }
    }
  }

/** pop up */
  #popUpMessage{
    //background: rgba(0,0,0,0.6);
    background: rgba(37, 173, 175, 0.8);
    position: fixed;
    width: 100vw;
    height:100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    .popUpMessage--text--block{
      background: $white;
      width: 58vw;
      //height: 70vh;
      top: 0;      
      margin: 4vh 0vw; 
      padding: 10vh 10vw;
      position: relative;
      p{
        font-size: 2rem;
        margin: 2rem;
      }
      .close-popup{
        bottom: 5vh;
        position: absolute;
        right: 5vw;
      }
    }
    #btnSocial{
      &.btn-default{
        white-space: normal;
      }
    }
  }

/** 404  **/
.error-404{
  padding: 0 0 5rem;
  .img-404{
   background: url('../style/img/page-not-found.png') center top no-repeat; 
   min-height: 30rem;    
 }
  .title-section {
    margin: 1rem 0 1rem;
  }
  p{
    margin: 0;
  }
}

/**** NOSCRIPT PAGE ***/
  #noScript {
    border: 1px solid #ccc;
    margin: 1.3em auto 2em !important;
    max-width: 900px !important;
    padding: 0 50px;
  }

  .introNoScript {
    border: 1px solid #000;
    color: $gray-base;
    font-size: 1.2em;
    font-weight: 700;
    margin: 2em 0 1.5em;
    padding: 1em;
  }
/**** END NOSCRIPT PAGE ***/

.survey--map--block{
  //background: $gray-lighter;
  display: block;
  .map--block{
    background: $gray-lighter;    
    .dvt--map--block, div[id^='dvt_map']{
      width: 70%;
      text-align: center;
      margin: auto;
      svg{
        //overflow: visible !important;
        z-index: 100000 !important;
        text{
          font: 0.9rem 'OpenSans-bold' !important;
          opacity: 1;
          cursor:default;
        }
      }
    }       
  }
}

/*** tooltip map ****/
.highcharts-tooltip-container{
  > svg{
    // display: none;    
  }
  .dvt-map-tooltip{
    background: none;
    color: #57575a;
    padding: 0.8rem 1.6rem;
    border: 0px solid $aquamarine;
    border-radius: 6px;
    position: relative;
    z-index: 1000000000;
    top: 0;
    left: 0;
    &:after {
      content: "";
      position: absolute;
      bottom: -3rem;
      left: 48%;
      margin-left: 0;
      border: 1rem solid transparent;
      border-top-color: $aquamarine;
    }
    &.botton:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width:1.2rem;
      border-style: solid;
      border-color: transparent transparent $aquamarine transparent;
    }
    &.top:after {
      content: "";
      position: absolute;
      bottom: -25px;
      left: 43%;
      margin-left: 0;
      border-width:1.2rem;
      border-style: solid;
      border-color: $aquamarine transparent transparent transparent;
    }
    .country-name{
      text-transform: uppercase;
      font-family:Oswald,sans-serif;
      font-size: 2.2rem;
      text-align: left;
    }
    ul{
      list-style-type: none;
      padding:0;
      li{
        text-align: left;
        font-size: 1.8rem;
        label{
          font-family: 'OpenSans-bold';
          margin-right: 0.5rem;
          font-size: 1.4rem;
        }
      } 
    }
  }
}

.tooltip-item{
  list-style-type: none;
  padding:0;
  li{
    text-align: left;
    font-family: 'OpenSans';
    font-size: 1.4rem;
    margin: 0.4rem 0.2rem;;
    strong{
      font-family: 'OpenSans';
      margin-right: 0.5rem;     
      font-weight: bold;
      &.tooltip-value{
        &:after{
          content: "\2194";
          font-size: 1.2em;
        }
        &.up{
          &:after{
            content: "\2195";
            font-size: 1.2em;
          }
        }
      }
    }
  }
}





.card--block--chart{
  .chart--wrapper{
    svg{
      rect, circle, path, line{
        cursor: default !important;
      }
    }
  }
}

.dvt-modal{
  svg{
    rect, circle, path, line{
      cursor: default !important;
    }
  }
}


/* hack IE11 */
@media all and (-ms-high-contrast:none)
{     
   .foo { color: green } /* IE10 */
   *::-ms-backdrop, .foo { color: red } /* IE11 */
  .survey--map--block .map--block .dvt--map--block svg, .survey--map--block .map--block div[id^='dvt_map'] svg{
    // height:680px !important
  }
}


.footer-elements, header, .export--data--modal, .pageUrl, .social--network--modal{
  a.external, 
  a[target="_blank"], 
  a[target="blank"]{
    margin-right: 0em;
    &:after{
      background: none;
      margin-left:0em;
      padding-right: 0;
      width: 1px;
      height: 1px;
      display: none;
    }
  }
}
a{
  &.btn-default{
    &[target="blank"]{
      margin-right: 0em;
      &:after{
        background:none;
        margin-left:0em;
        padding-right: 0;
        width: 1px;
        height: 1px;
        display: none;
      }
    }
  }    
}

@-ms-viewport{
width:auto!important;
}
body{
  -ms-overflow-style: scrollbar;
}
svg:not(:root) {
  overflow: visible !important; 
}

.survey--map--block .map--block .dvt--map--block svg, 
.survey--map--block .map--block div[id^='dvt_map'] svg {
    z-index: 100000 !important;
    // overflow: hidden !important; 
}

div[query="getNationalBarChartData"] svg{
  overflow: visible !important; 
}
/*
.ribbon-eu{
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #eee;
  font-size: 14px !important;
  padding: 0 0 0 16px;
  min-height: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  @media (max-width: 1023px){
    width: 100%;
    flex: 100%;
  }
  &:before {
    content: " "!important;
    display: inline-block;
    width: 24px;
    height: 16px;
    background: center center / 110% auto no-repeat #004494 url(https://europa.eu/webtools/images/flag.svg?t=1685966179);
    margin: 0px 8px 0 0;
  }
  span{
    @media (max-width: 480px){
      display:none;
    }
  }
    
  > a {
    text-decoration: none;
    color: $black;
    background: #eee;
    font-weight: 400;
    margin: 0 0 0 4px;
    padding: 0 4px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:after {
      content: " "!important;
      vertical-align: initial;
      border: 0;
      display: inline-block;
      width: 20px;
      height: 8px;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      -ms-transition: all .3s;
      transition: all .3s;
      margin-left: 2px;
      background: center center / 12px auto no-repeat transparent url(https://europa.eu/webtools/images/chevron-grey.svg?t=1685966179);
    }
  }
  &.open{
    > a{
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdown-toggle{
    &:hover{
      background: $white;
    }
  }
  .dropdown-menu{
    background-color: #fff;
    position: absolute;
    right: initial;
    left: 1rem;
    top: 100%;
    padding: 12px 16px;
    margin: 0;
    max-width: 480px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    color: #444;
    font-size: 14px;
    @media (max-width: 470px){
      width: 100%;
      max-width: 100%;
      left: 0;
    }
    .dropdown-item{
      padding: 0;
      margin:0 0 8px 0;
      white-space: pre-wrap;
      &:hover, &:focus {
        text-decoration: none;
        background-color:transparent;
        a{
          color:$aquamarine;
        }
      }
    }
  }
}
.our-websites-menu{
  background:$aquamarine;
  color: $white;
  margin-top: 0;
  position: relative;
  @media (max-width: 1023px){
    width: 100%;
    flex: 100%;
    .container{
      width: 100%;
    }
  }
  .view-our-webs{
    display: none;
    padding: 0.5rem 16px;
    margin: 0;
    font-weight: normal;
    @media (max-width: 1023px){
      display: block;
      &:after {
        content: " "!important;
        vertical-align: initial;
        border: 0;
        display: inline-block;
        width: 20px;
        height: 8px;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -o-transition: all .3s;
        -ms-transition: all .3s;
        transition: all .3s;
        margin-left: 2px;
        background: center center / 12px auto no-repeat transparent url(https://europa.eu/webtools/images/chevron-white.svg?t=1685966179);
      }
    }
  }
  nav{
    @media (max-width: 1023px){
      display: none;
      position: absolute;
      z-index: 10000;
      top: 22px;
      background:$aquamarine;
    }
    ul{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 1023px){
        justify-content: space-between;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 0 0.5rem 1.5rem;
        flex-direction: column;
        margin: 1rem 2rem;
        border-left: 4px solid;
      }
      li{
        .nav-link{
          padding: 0.5rem 1rem;
          color: $white;
          @media (max-width: 1023px){
            padding: 0.5rem 0.5rem 0.5rem 0;
          }
          &:hover{
            color: $black;
          }
        }
        span.nav-link{
          font-weight: 500;
          &:hover{
            color: $white;
          }
        }
        &.active{
          background: $white;
          a{
            color: $black;
          }          
        }
      }
    }
  }
}
*/

.ribbon-eu{
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #eee;
  font-family: 'Roboto';
  line-height: 1.5;
  font-size: 14px !important;
  padding: 0 0 0 16px;
  min-height: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  @media (max-width: 1023px){
    width: 100%;
    flex: 100%;
  }
  &:before {
    content: " "!important;
    display: inline-block;
    width: 24px;
    height: 16px;
    background: center center / 110% auto no-repeat #004494 url(https://europa.eu/webtools/images/flag.svg?t=1685966179);
    margin: 0px 8px 0 0;
  }
  span{
    @media (max-width: 480px){
      display:none;
    }
  }
    
  > a {
    text-decoration: none;
    color: $black;
    background: #eee;
    font-weight: 400;
    margin: 0 0 0 4px;
    padding: 0 4px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:after {
      content: " "!important;
      vertical-align: initial;
      border: 0;
      display: inline-block;
      width: 20px;
      height: 8px;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      -ms-transition: all .3s;
      transition: all .3s;
      margin-left: 2px;
      background: center center / 12px auto no-repeat transparent url(https://europa.eu/webtools/images/chevron-grey.svg?t=1685966179);
    }
  }
  &.open{
    > a{
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdown-toggle{
    &:hover{
      background: $white;
    }
  }
  .dropdown-menu{
    background-color: #fff;
    position: absolute;
    right: initial;
    left: 1rem;
    top: 100%;
    padding: 12px 16px;
    margin: 0;
    max-width: 480px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,.4);
    color: #444;
    font-size: 14px;
    @media (max-width: 470px){
      width: 100%;
      max-width: 100%;
      left: 0;
    }
    .dropdown-item{
      padding: 0;
      margin:0 0 8px 0;
      white-space: pre-wrap;
      &:hover, &:focus {
        text-decoration: none;
        background-color:transparent;
        a{
          color:$aquamarine;
        }
      }
    }
  }
}
.our-websites-menu {
  background:$aquamarine-dark;
  color: $white;
  margin-top: 0;
  position: relative;
  font-family: 'Roboto';
  font-size: 1.6rem;
  width: 100%;
  @media (min-width: 1200px){
    width: 100%;
    flex: 100%;
    .container{
      max-width: 100%;
    }
  }
  @media (max-width: 1023px) {
    display: none;
  }
  .view-our-webs{
    display: none;
    padding: 0.5rem 16px;
    margin: 0;
    font-weight: normal;
  }
  nav{
    ul{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // @media (max-width: 1023px){
      //   justify-content: space-between;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   padding: 0.5rem 0 0.5rem 1.5rem;
      //   flex-direction: column;
      //   margin: 1rem 2rem;
      //   border-left: 4px solid;
      // }
      li{
        .nav-link{
          padding: .75rem 1rem;
          color: $white;
          @media (max-width: 990px){
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
          }
          &:hover{
            color: $white;
            text-decoration: underline;
            background: transparent !important;
          }
        }
        span.nav-link{
          font-weight: 400;
          &:hover{
            color: $white;
            text-decoration: none;
          }
        }
        &.active{
          background: $white;
          a{
            color: $aquamarine;
            &:hover{
              color: $aquamarine;
              text-decoration: underline;
            }
          }          
        }
      }
    }
  }
}