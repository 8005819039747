@mixin transition($prop, $time){
	-webkit-transition: $prop $time ease-out;
	-moz-transition: $prop $time ease-out;
	-o-transition: $prop $time ease-out;
	transition: $prop $time ease-out;
}


@media (max-width: 1480px) {
  /**** COKKIES DIWSCLAIMER ****/
  #cookiesConsent {
    background: $backgroundCookies;
    width: 100%;
    padding:1.5em;
    overflow: auto;
    z-index:1000001;
    top: 0;
    position: fixed;
    color: #666;
    .text {
      float:none;
      width:100%;
      text-align: center;
    }
    .buttons {
			text-align: center;
			padding: 1rem 0 1rem;
			display: flex;
			justify-content: center;
			> a{
				order: 3;
			}
			> .accept{
				order: 1;
			}
			> .decline{
				order: 2;
			}
    }
    button {
      float:none;
      margin:0 .5em;
    }
  }
}

@media only screen 
and (min-width : 1024px) 
and (max-width : 1380px){
	.home-cover {
		text-align: center;
		cursor: pointer;
		min-height: 12rem;
		.main-title{
			//margin: 2.5rem 0 0 0rem;
			img{
				height: 4.3rem;
			}
			.main-text{
				font-size: 3.6rem;
			}
		}
		.lead-title{
			font-size: 3.6rem;
		}
	}
}

@media (max-width: 1080px) {
  .bar-main-menu{
    #main-menu{
    	//padding: 0;
			.nav {
				> li {
					> a{

					}
				}
			}
    }
  }
}

/* breakpoint for respopnsive menu  */
@media (max-width: 1180px) {
	.navbar-nav {
	>li {
		>a {
			// padding-left: 8px;
			// padding-right: 8px;
		}
	}
	}
	.bar-header {
		.header--top{
			.menu-tools-top{
				li {
					padding-left: 1rem;
					&.lang--block {
						right: 0 !important;
					}
				}
			}
		}
	}
	#cookiesConsent {
		flex-direction: column;
	}
}
@media (max-width: 1023px) {
	
	body{
	  padding-top:16.3rem;
	}
	.tablet-show{
		display: block;
	}
	.affix {
		top:88px;
	}
	.bar-header{
		//position: relative;
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		flex-wrap: wrap;
		height:16.5rem;
		padding:0; 
		.bar-main-menu {
	    .navbar-header {
	        float: none;
	    }
	    .navbar-left,.navbar-right {
	        float: none !important;
	    }
	    .navbar-toggle {
	        display: block;
	    }
	    .navbar-collapse {
	        //border-top: 1px solid transparent;
	        //box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	        border-top: 0;
  				box-shadow: none;
  				padding: 0 !important;
	    }
	    .navbar-fixed-top {
	        top: 0;
	        border-width: 0 0 1px;
	    }
	    .navbar-collapse.collapse {
	        //display: none!important;
			border-top: 0;
			box-shadow: none;
			left:0%;
			top:-100rem;
			position: fixed;
			width: 100%;
			padding: 0 !important;
	    }
	    .navbar-nav {
	        float: none!important;
	        margin-top: 7.5px !important;	        
		    >li {
		        float: none;
			    >a {
			        padding-top: 10px;
			        padding-bottom: 10px;
			        border: 1px solid transparent;
			    }
		    }
	    }
	    .collapse{
	    	left:0%;
	    	top:9rem;
	    	padding: 0 !important;
	    }
	    .collapse.in{
	    }			
		}
		.header--top{
			justify-content: flex-start;
			width: 100%;
			border-bottom: 1px solid #ccc;
			background:$white;
			.eu-osha-logo{
				width: auto;				
				> a{
					padding: 0.5rem 0.5rem 0.5rem 2rem;
					img{
						height:auto;
					}
					&:nth-child(2) {
						padding: 0.5rem 0.5rem 0.5rem 0.5rem;
						img {
							width: 250px;
						}
					}
				}
			}
			.nav--tools--menu{
			  flex-direction: row;
			  justify-content: flex-end;
			  position: initial;
			}
			.menu-tools-top{
				width: auto;
				padding: 1.5rem 0;
				float: none;
				background: $white;
				height: 90px;
		    .menu-tools-top--items{
		    	padding: 0rem 2.5rem 0rem 1.5rem;
		    	margin: 0;
					flex-direction: row-reverse;
					display: flex;
		      .zoom--text{
		        display: none;        
		      }
		      .search--block{
		      	padding: 0rem 1rem;
			      .icon-glass-tablet{
			      	color: $aquamarine;
			      	font-size: 2.5rem;
			      	&.exposed{
								color: $gray-icon;
			      	}			      	
			      }
		        .search--form{
						  position: absolute;						  
						  width: 100%;
						  left: 0;
						  top: 0;
						  background:$white;
						  padding: 4rem 14rem;
						  visibility:hidden;
						  z-index: -1;
						  transition: 0.4s;		
						  &.exposed{
						  	visibility: visible;
						  	top: 8.7rem;
						  	transition: 0.6s;	
						  	box-shadow: 0 0px 2px #ccc;						  
			          .form-group {
									width: 100%;
									border: 1px solid #A2ACB4;
			          }
			          input.form-control {
			            padding: 2.5rem;
	  							font-size: $font-size-body;
			          }
			          .form-group:hover,
			          .form-group.hover {
			          }
			          .form-control-feedback {
			          	top: 1rem;
			          }
			        }
		        }       
		      }
		      .print--block{
		        display: none;
		      }
		      #google_translate_element_wrapper{
		      	margin: 0;
		      	padding: 0 2rem 0 0;
						top: -3px;
						position: relative;
		      }
		    }
			}
		}
		.bar-main-menu {
			display: block;
			width: auto;
			padding: 2rem 0;
			margin: 0;
			border: 0;
			.navbar-nav{
			  // box-shadow: 0 6px 0 0px #f6a400 !important;
				> li{
					padding: 1rem 0rem 0;
					background:$white;
					border-bottom: 1px solid #ccc;
					&.main-menu-selected {
						box-shadow: none !important;
						// background:$gray-lighter;
						&:after {
								content: "";
								width: 100%;
								height: 4px;
								display: block !important;
								background: $orange;
							}	
					}	
					&:hover{
						box-shadow: none !important;
						&:after {
							display: none;
						}							
					}
					&:after {
						// display: none !important;
					}
					> a{
						font-size:1.5rem !important;
						padding: 1rem 1rem 1rem 3rem !important;
						box-shadow: none !important;
						&:hover{
							// border-bottom: 1px solid $aquamarine;							
						}
					}					
					ul.dropdown-menu{
						display: none !important;
					}
				}
			}
	    &.exposed{
				display: block;
				width: auto;
				padding: 2rem 0;
				margin: 0;
				position: static;
				left: 0;
				top: 0;	
				.collapse{
					&.exposed{
						display: block !important;
						background: #FFF;
						height: 90vh !important;
						position: absolute;
						width: 40%;
						top: 16.5rem;
						left: 60%;
						border-left: 1px solid #ccc;
						.navbar-nav{
							margin: 0;
							padding: 0rem;
							width: 100%;
							// background:#F1FFFF;
							flex-direction: column;
							li.open{
								box-shadow: none !important;								
								ul.dropdown-menu{
									display: block !important;									
									float: none;
									position: relative;
									top: 0;
									box-shadow: none;
									border: 0;
								  li{
								  	padding: 0;
									&:first-child {
										padding: 1rem 1.5rem 1rem 3.5rem;
									}
								  	a{
								  		font-size: 1.5rem !important;
							  		  padding-left: 5rem;
							  		  &:hover {
							  		  	background:#F1FFFF !important;
							  		  }
								  	}
								  }
								}
							}
						}
					}
				}

	    }
		  .navbar-toggle {
		  	margin: 1rem;
		  	.icon-bar {
				  color: $aquamarine;
				  background: $aquamarine;
				  width: 32px;
				  height: 4px;
				  transition: 0.4s;
		  	}
		  	&.exposed{
		      .bar1 {
		        -webkit-transform: rotate(-45deg) translate(-4px, 3px);
		        transform: rotate(-45deg) translate(-4px, 3px);
		      }
		      .bar2 {
		        opacity: 0;
		      }
		      .bar3 {
		        -webkit-transform: rotate(45deg) translate(-8px, -8px);
		        transform: rotate(45deg) translate(-8px, -8px);
		      }
			  }
		  }
		}
	}
	.breadcrumbs--social--network{
		top: 0;
	}
	.home-cover{
		top: 0;
		> .container{
			width: 100% !important;
		}
	}
}

@media (max-width: 800px) {
	#google_translate_element_wrapper{
			.google_translate_span {
			  top:0px;
			  display: block;
			}
			.gtranslator-info {
			  right: -2rem;
			  top: -5rem;
			  position: relative;
			}
	}
}

@media only screen 
and (min-width : 768px) 
and (max-width : 1024px)  {
	.container {
		width: 100%;
		max-width: 100%;
		.container {
			width: auto;
		}
	}
	.our-websites-menu {
		.container {
			padding: 0;
		}
	}
	.home-cover {
		top: 0rem;
		min-height: 9rem;
		text-align: center;
		.main-title{
			padding: 1rem 0rem;
			img{
				height: 4.3rem;
			}
			.main-text{
				vertical-align: middle;
			}
		}
		.lead-title{
			font-size: 2.6rem;
			line-height: 3.2rem;
			padding: 0 1.5rem;
			margin-left: 1.5rem;
			display: inline-block;
			width: 35rem;
			text-align: left;
		}
	}
  .datafor--dropdown--wrapper{
    height: 6rem;
    margin-top: 1rem;
    .datafor--dropdown--list{
    	width: 50%;
    	margin: 0 25%;
    }
  }
  .selected--tags-wrapper{
		text-align: center;
  }
/** pop up */
  #popUpMessage{
    background: rgba(0,0,0,0.6);
    position: fixed;
    width: 100vw;
    height:100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    .popUpMessage--text--block{
			background: #FFF;
			width: 100%;
			height: 90vh;
			top: 0;
			margin: 5vh 0vw;
			padding: 5vh 0vw;
			position: relative;
      .close-popup{
        bottom: 5vh;
        position: absolute;
        right: 5vw;
      }
    }
  }
  .full-tablet{
    width: 100% !important;
  }
	/** WIDTH CARDS  **/
		.card--grid{
		  justify-content: flex-start;
		}
		.xs-w1{
			.card--block{
				width:50%;
				margin: 0 1% 3rem;
			}
			.card--block--rounded{
				width:48%;
				margin: 0 1% 3rem;
			}
			.card--block--chart{
				width:100%;
				margin: 0 1% 3rem;
			}
		}
		.xs-w2{
			.card--block{
				width:50%;
				margin: 0 1% 3rem;
			}
			.card--block--rounded{
				width:48%;
				margin: 0 1% 3rem;
			}
			.card--block--chart{
				width:48%;
				margin: 0 1% 3rem;
			}
		}
		.xs-w3{
			.card--block{
				width: 33.333%;
				margin: 0 1% 3rem;		
			}
			.card--block--rounded{
				width:31%;
				margin: 0 1% 3rem;
			}
			.card--block--chart{
				width:31%;
				margin: 0 1% 3rem;
			}
		}
		.xs-w4{
			.card--block{
				width:25%;
				margin: 0 1% 3rem;
			}	
			.card--block--rounded{
				width:23%;
				margin: 0 1% 3rem;
			}
			.card--block--chart{
				width:23%;
				margin: 0 1% 3rem;
			}
		}
		.xs-w5{
			.card--block{
				width:20%;
				margin: 0 1% 3rem;		
			}
			.card--block--rounded{
				width:18%;
				margin: 0 1% 3rem;
			}
			.card--block--chart{
				width:18%;
			  margin: 0 1% 3rem;
			}
		}
	/** END WIDTH CARDS  **/ 

	/** ELEMENTS  **/
		.advice--block{
			.title--section{
			  font-family: 'Oswald';
			  font-size: 3.6rem;
			  padding: 1rem;
			  text-align: center;
			}
		}
		.advice--icon--block{
			.title--section{
				font-family: 'Oswald';
				font-size: 3.6rem;
				padding: 0;
				text-align: left;
			}
		}
		.carousel--card{
			.carousel--card--block {
				.carousel-inner {
					min-height: auto;
					padding-bottom: 3rem;
					.carousel--text--block{
						flex-direction: column;
						.number-text{
							.number-symbol{
							}
						}
						.text-area{
						}
					}
				}
				.carousel-indicators{
				}
				.carousel-control{
				}
			}		
		}
		.xs-row{
			flex-direction: column;
			li{
				width: 100% !important;
			}
		}
	/** END ELEMENTS  **/
	.highcharts-tooltip-container{
		// position: fixed !important;
		// top: 10% !important;
		left: 60% !important;
		> svg{
		  display: none;    
		}
		.dvt-map-tooltip{
		  background:rgba(255,255,255,0.9);
		  color: $gray;
		  padding: 2rem;
		  border: 2px solid $aquamarine;
		  border-radius: 6px;
		  position: absolute;
		  z-index: 1000000000;
		  top: -7rem;
		  left: 0rem;
		  &:after {
			border: none;
		  }
		  &.botton:after {

		  }
		  &.top:after {

		  }
		}
	}

}


@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) 
and (orientation : landscape) { 

}



@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) 
and (orientation : portrait) { 
	.grid-2{
	  .col-img{
  		img{
  			padding-top: 2.5rem;
  		}
	    a{
	    	//padding-top: 2rem;
	      img{
	        &:hover{
	        }
	      }     
	    }
	  }
	  .col-content{
	  	padding: 0;
	    .title--section{

	    }
	    h2.title--section{
	      font-size: 3rem;
	      padding-top: 2rem;
	    }
	    // .carousel--home{
		// 		margin: 2rem 0;
		// 		.carousel-indicators{
		// 			bottom: -2rem;
		// 		}
		// 		.carousel-inner{
		// 			width: 100%;
		// 			padding: 0;
		// 		}
	    // }
	  }
	}
	.slide-container{
		.col-img{
			img{
				padding-top: 0;
			}
		}
	}
	.datavisualisation-page{
		.section--page {
		  margin: 0.5rem 0;
		  padding: 2rem 0 0rem;
			.icon--card {
				background-position: center 1rem !important;
				background-size: auto 50% !important;
				height: 15rem !important;
				padding: 1rem !important;
			}
		}
	}

}



@media only screen 
and (max-width :767px)  { 
	.container {
		width: 100%;
		max-width: 100%;
		.container {
			width: auto;
		}
	}
	.our-websites-menu {
		.container {
			display: none;
		}
	}
	.col-xs-12 {
		width: 100%;
		max-width: 100%;
	}
	body{
		padding-top:15rem;
	}
	.home-cover {
		top: 0rem;
		min-height: 11rem;
		text-align: center;
		.main-title{
			padding: 1rem 0rem;
			img{
				height: 4.3rem;
			}
			.main-text{
				vertical-align: middle;
			}
		}
		.lead-title{
			font-size: 2.6rem;
			line-height: 3.2rem;
			padding: 0 1.5rem;
			margin-left: 1.5rem;
			display: inline-block;
			width: 35rem;
			text-align: left;
		}
	}
	.grid-2{
	  padding: 2rem 0;
	  display: flex;
	  flex-direction: column;
	  .col-img{
	  	order: 1;
  	  padding: 2rem 1rem;
	    a{
	      img{
	        &:hover{
	        }
	      }     
	    }
	  }
	  .col-content{
	  	order: 2;
	    .title--section{

	    }
	    h2.title--section{
	      font-size: 3rem;
	      padding-top: 2rem;
	    }
	    .btn-default{
				width: 100%;
				text-align: center;
	    }
	  }
	}
	.carousel--home{
		margin: 2rem 0;
		padding: 0rem 0 5rem;
		min-height: auto;
		height: auto;
		.carousel-indicators{

			li, li.active{
				width: 2.2rem;
				height: 2.2rem;
			}
		}
		.carousel-inner {
			width: 100%;
			min-height: fit-content;
			height: auto;
			overflow: visible;
			.carousel-item{
				position: relative;
				min-height: fit-content;
				.carousel-caption{
					position: relative;
					min-height: fit-content;
				}
			}
		}
	} 
	.breadcrumbs--social--network{
		top:0rem;
		flex-direction: column-reverse;
		padding: 1rem 0 1rem 1rem;
		.breadcrumbs{
			align-self: flex-start;
		}
		.social--network--nav{
			align-self: flex-end;
			align-items: center;
			ul{
				padding: 0;
				li{
					padding: 0 .5rem;
					height: 3rem;
					button {
						width: 2.2rem;
						height: 2.2rem;
						overflow: hidden;
						svg{
							position: relative;
							top: -1rem;
							left: -1rem;
						}
					}
				}
			}
		}		  
	}
	.mainBody{
		position: relative;
		top: 0rem;
	}
	.section--page {
	  padding: 3rem 2rem;
	}

	/** WIDTH CARDS  **/
		.xxs-w1{
			.card--block{
				width:100%;
				margin-bottom: 3rem;
			}
			.card--block--rounded{
				width:100%;
				margin-bottom: 3rem;
			}
			.card--block--chart{
				width:100%;
				margin-bottom: 3rem;
			}
		}
		.xxs-w2{
			.card--block{
				width:50%;
			}
			.card--block--rounded{
				width:48%;
			}
			.card--block--chart{
				width:48%;
			}
		}
		.xxs-w3{
			.card--block{
				width: 33.333%;		
			}
			.card--block--rounded{
				width:31%;
			}
			.card--block--chart{
				width:31%;
			}
		}
		.xxs-w4{
			.card--block{
				width:25%;
			}	
			.card--block--rounded{
				width:23%;
			}
			.card--block--chart{
				width:23%;
			}
		}
		.xxs-w5{
			.card--block{
				width:20%;		
			}
			.card--block--rounded{
				width:18%;
			}
			.card--block--chart{
				width:18%;
			}
		}
	/** END WIDTH CARDS  **/

	/** ELEMENTS  **/
		.advice--block{
			top: 0rem;
			&.advice-home{
				top: 0rem;
			}
			.title--section{
			  font-family: 'Oswald';
			  font-size: 3.6rem;
			  padding: 1rem;
			  text-align: center;
			}
		}
		.carousel--card{
			.carousel--card--block {
				.carousel-inner {
				  min-height: auto;
				  padding-bottom: 3rem;
				  .carousel--text--block{
				  	a{
				  		display: block;
				  		width: 100%;
				  	}			  		
				  }
				}
			}
		}
    .column--grid--block{
      flex-direction: column;
      .column--item{
        padding: 0;
        h2{
          font-size: 2.4rem;
        }
        &.first{
        	border-right: 0;
        	padding-bottom: 2rem;
          border-bottom: 2px solid $aquamarine-light;
          margin-bottom: 3rem;
          .columm--item--content{
            
          }
        }
      }
    }
	/**	END ELEMENTS  **/
}


@media only screen 
and (max-width :767px)  {

	.hide-mobile{
		display: none;
	}
	.logos-mobile {
		display: flex;
		align-items: center;
			> a {
			display: flex !important;
			justify-content: flex-start;
			align-items: center;
			padding: 0.5rem 0 !important;
			img {
				margin: 0 0 0 2rem;
			}
		}
	}
	.show-mobile{
		display: block;
	}
  .hide-header{
    position: fixed;
    top: -20rem;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    &.affix, &.affix-top{
    	top: -25rem;
    	background: $white;
    	width: 100%;
    }
  }
  .show-header{
    position: fixed;
    top:0rem;
    -webkit-transition: all 0.8s; 
    transition: all 0.8s;
    &.affix, &.affix-top{
    	top:9rem;
    	background: $white;
    	width: 100%;
    }
  }
	.bar-header{
		height:auto;
		.header--top{
			flex-direction: row;
			display: flex;
		  background: $white;
			.eu-osha-logo{
				width: 100%;
				border-bottom: 1px solid #ccc;
				> a{
				  display: none;
				  text-align: center;
				  padding:0; 
				  img{
						padding-bottom: 0rem;
				  }
				}
			}
			.nav--tools--menu{
				width: 50%;
				order: 3;
				.menu-tools-top{
					padding: 0;
					height: auto;
					.menu-tools-top--items{
 						.lang--block{
							.lang--form{
								select{
									font-size: 1.5rem;
									padding: 1rem;
								}
							}
							&:after{
								top: 2px;
							}
 						}  
					}
				}				
			}
			.bar-main-menu{
				padding: 0;
				margin: 0;
				width: auto;
				order: 2;
				&.exposed{
				  left: 0;
				  top: auto;
					.collapse{
						&.exposed{
						  top: initial;
						  min-height: auto;
				      width: 100%;
				      left: 0;
				      height: auto !important;
						  .navbar-nav {
				  	    border-top: 1px solid #ccc;
						  }
						}
					}
				}
			}
		}
	}
	.submenu--level2{
		padding: 0;
		.submenu--items--wrapper{
			//min-height: 7rem;
			min-height: auto;
			overflow: hidden;
			display: block;
		  margin: 0;
			li{
				width: 100%;
				//padding: 0 0rem 1.5rem 1rem;
				padding: 0;
				display: none;
				position: relative;
				a{
					display: block;
					padding: 1rem 0;
					font-size: 2.4rem;
				}
				&.active{
					display: block;
					a, a:hover, a:visited, a:focus{
					  background: #FFF;
					  border: none;
				    outline: none;
					  color: #F6A400 !important;
					  font-size: 3rem;
					  pointer-events: none;
					}
					&:after{
						content: "\f078";
						font: normal normal normal 2rem/1 FontAwesome;
						right: 1.5rem;
						top: 1rem;
						padding: 1rem;
						position: absolute;
						pointer-events: all;
					}	
				}
				&:nth-child(1){
					&:after{
						content: "\f078";
						font: normal normal normal 2rem/1 FontAwesome;
						right: 1.5rem;
						top: 1rem;
						padding: 1rem;
						position: absolute;
						pointer-events: all;
					}	
				}
			}
			&.open-list{
				li{
					display: block;
					&.active{
						display: block;
						a, a:hover, a:visited, a:focus{
							border-bottom: 0;
						}
						&:after{
							content: "";
						}						
					}
					&:nth-child(1){
						a{
							border-bottom: 0;
						}
						&:after{
							content: "\f077" !important;
							font: normal normal normal 2rem/1 FontAwesome;
							right: 1.5rem;
							top: 1rem;
							padding: 1rem;
							position: absolute;
						}
					}
				}
			}
		}
	}
	.section--page {
	  padding: 1rem 0rem 0;
	}
  .level3--wrapper{
    .submenu--level3{
			height: 7rem;
			overflow: hidden;
			position: relative;
		  margin: 0;
			.submenu--arrows--wrapper{
				li{
					list-style-type: none;
					position: absolute;
					a{
						font-size: 3rem;
						font-weight: bold;
						color: $aquamarine;
						cursor: pointer;
						width: 4rem;
						text-align: center;
						display: block;
						&:hover{
							color: $orange;
						}
					}
					&.left{
						left: 1rem;
						top: 1rem;						
					}
					&.right{
						right: 1rem;
						top: 1rem;
					}
				}
			}
      .submenu--items--wrapper{
        flex-direction: column;
        li{
          width: 100%;
          text-align: center;
          margin-bottom: 3rem;
          display: none;
          a{
            padding: 1rem 8rem 1rem 2.6rem;           
            &.active {
              display: block;
              border: none;
            }
          }
          &.active{
          	display: block;
            a, a.active, a:hover, a:focus, a:visited {
              color: $aquamarine;
              font-family: "OpenSans-Bold", sans-serif;
            }
          }
        }
      }
    }    
  }
  .datafor--dropdown--wrapper{
    height: 6rem;
    margin-top: 4rem;
    .datafor--dropdown--list{
    	width: 70%;
    	margin: 0 15%;
    }
  }
  .selected--tags-wrapper{
		text-align: center;
  }

/** pop up */
  #popUpMessage{
    .popUpMessage--text--block{
		  width: 90vw;
		  height: 90vh;
		  margin: 5vh 5vw;
		  padding: 5vh 5vw;
      .close-popup{
        bottom: 3vh;
        right: 5vw;
      }
    }
  }

	form.compare--block--form{
		text-align: center;
	}
  .compare--block{
    .compare--list{
			padding: 0;
			flex-direction: column;
			display: inline-flex;
			align-items: flex-end;
			margin: auto;
			width: auto;
      li{
      	width:auto;
        label{          
          &:after{
          }
        }
        select{
          font-size: 2rem;
          option{
            font-size: 1.5rem; 
            &:checked{            
            }
          }
        }
      }
    }
  }
	.block--filter--wrapper{		
		display: block !important;
		.filter-text{
			margin: 0!important;
			top:auto !important;
		}
		.filter--dropdown--wrapper{
			margin: 1.8rem 0 0 0;
			> label{
				min-height: auto !important;
			}
			.filter--dropdown--list{
				position: relative !important;
				margin:0;
			}
		} 
	}
	.matrix--elements--wrapper {
	  word-break: break-word;
	  .matrix--element{
	  	 padding: 2rem !important;
	  	 margin: 0 0rem 1.5rem 0rem !important;
	  	.matrix--header--elements{
				.flags--wrapper{
					width: 5rem;
				}
	  	}
	  }
	}
	.highcharts-tooltip-container{
		// position: fixed !important;
		// top: 10% !important;
		left: 0% !important;
		> svg{
		display: none;    
		}
		.dvt-map-tooltip{
			top: -12rem;
			left: 0rem;
			width: 90vw;
		.country-name{
			font-size: 1.6rem;
		}
		ul{
			li{
				font-size: 1.4rem;
			}
		}
		&:after {
			border: none;
		}
		&.botton:after {

		}
		&.top:after {

		}
		}
	}
	.map--block{
		svg{
			.highcharts-data-labels{
				text{
					font-size: 10px !important;
				}	
			}	
		}	
	}
	.european-map {
		.map--block{ 
			.highcharts-container{
				svg{
					path{
						stroke: #FFF;
						stroke-width: 1px;
					}
				}
			} 
		}
	}
	.european-bar-chart{
		.chart--block{
			.highcharts-container{
				svg{
					text{
						font-size: 10px !important;
					}
				}
			} 
		}
	}
	.map--gauss--block{
		.highcharts-container{
			height: 160px !important;
			svg{
				left: 0;
				position: absolute;
				top: -4rem;
			}
		}
	}


}

@media only screen 
and (max-width :620px) {
	.home-cover {
		top: 0rem;
		min-height: 11rem;
		text-align: center;
		.main-title{
			padding: 1rem 0rem;
			display: block;
			img{
			}
			.main-text{
				font-size: 2.8rem;
			}
		}
		.lead-title{
			font-size: 2.4rem;
			padding: 0 1.5rem;
			margin-left: 0;
			display: block;
			width: 100%;
			text-align: center;
			border: 0;
		}
	}
  #popUpMessage{
    .popUpMessage--text--block{
		  width: 90vw;
		  height: 90vh;
		  margin: 5vh 5vw;
		  padding: 5vh 5vw;
		  overflow-y: auto;
		  overflow-x: hidden;
      .close-popup{
			  bottom: -3rem;
			  right: auto;
			  left: 25%;
			  position: relative;
			  margin-bottom: 4rem;
      }
    }
  }
  /**** COKKIES DIWSCLAIMER ****/
  #cookiesConsent {
    .text {
    	text-align: left;
    }
    .buttons {
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 100%;
			> a {
				width: 100%;
				display: block;
			}
    }
    button {
      width: 100%;
      margin: 1.4rem 0;
    }
  }
	body{
		padding-top:15rem;
	}
}

@media only screen 
and (max-width :550px){
	.level3--wrapper{
		.submenu--level3{
			.submenu--items--wrapper{
				li{
					a{
						font-size: 2.2rem;
					}
				}
			}
		}
	}
}

@media only screen 
and (max-width :420px){
	.hide-header {
	  top: -20rem;
	  -webkit-transition: all 0.8s;
	  transition: all 0.8s;
	}
	.level2--wrapper{
		padding:0;
		.submenu--level2{
			.submenu--items--wrapper{
				li:nth-child(1):after{
					right:0rem;
				}
			}
		}
	}
	.level3--wrapper{
		padding: 0;
		.submenu--level3{
				.submenu--arrows--wrapper{
					li{
						a{
							padding:0.5rem 0 !important;
							width: 3rem;
							&:hover{
							}
						}
						&.left{
							left: 0rem;
							top: 0rem;						
						}
						&.right{
							right:0rem;
							top:0rem;
						}
					}
				}
		.submenu--items--wrapper{
			li{
			a{
				padding: 0 0.5rem !important;
				line-height: 2.6rem;
				&.active {
				}
			}
			&.active{
				a, a.active, a:hover, a:focus, a:visited {
							padding: 0.5rem 2rem !important;
				}
			}
			}
		}
		}    
	}
	.map--block{
		svg{
			.highcharts-data-labels{
				text{
					font-size: 8px !important;
				}
			}
		}
	}
}

@media only screen 
and (max-width :380px){
	
}

