@mixin scale-svg($w, $h) {
	-ms-transform: scale($w, $h);
	-webkit-transform: scale($w, $h);
	-moz-transform: scale($w, $h);
	transform: scale($w, $h);
}


@media print {
	.menu-tools-top, 
	.bar-main-menu, 
	.icon--card, 
	.btn--block, .btn--card, .btn-default, 
	//.block--filter--wrapper, 
	.see-more, .see-less, 
	.social--network--nav, 
	.footer-social-network, 
	.footer-nav-accessibility, 
	.question--by--question, 
	.questions--tree, 
	.select2-drop, 
	.questions--list--wrapper, 
	.disabled, 
	.content--lang--wrapper, 
	.social--network--nav, 
	.go-to, 
	#cookiesConsent,
	.ribbon-eu,
	.our-websites-menu{
	  display: none !important;
	}
	.grid-2 .col-img a img, .grid-2 .col-img div img{
		width: auto;
	}
	a[href]:after {
	  content: "";
	  width: 0;
	}
	a[target="_blank"], a[target="blank"]{
    margin-right: 0;
	}
	.matrix--content--elements  samp{
		display: block !important;
	}
	@page {
		size: auto;
		margin: 15mm 25mm 25mm 25mm; 
		
	}
	html{
		font-size: 8px !important;
	}
	body{
		padding: 0;
	}
	h2{
		font-size: 2.8rem !important;
		margin: 0 0 1.5rem 0;
	}
	h3{
		font-size: 2.2rem !important;
		margin: 0 0 1.2rem 0;
		color: $gray !important;
	}
	.container{
		padding: 0;
		width: 100%;
		max-width: 100%;
		min-width: 100% !important;
	}
	.col-xs-12 {
		width: 100%;
		flex: 100%;
		float: none;
		max-width: 100%;
	}

  /** WIDTH CARDS  **/
    .w2, .w3, .w4, .w4{
      .card--block, .card--block--rounded, .card--block--chart{
        width:100%;
        text-align: left;
        padding: 0rem;
        margin: 0;
        //page-break-inside : avoid;
      }
      .card--block--rounded{
        width:100%;
        text-align: left;
        padding: 0rem;
        margin: 0;
        //page-break-inside : avoid;
      }
      .card--block--chart{
        width:100%;
        text-align: left;
        padding: 0rem;
        margin: 0;
        //page-break-inside : avoid;
      }
    }
  /** END WIDTH CARDS  **/
	header{
		display: flex;
    	flex-wrap: wrap;
		align-items: center;
		.bar-header{
			display: block;
			position: relative !important;
			border-bottom:0px !important;
			height: auto !important;

			flex: 33%;
			// height: 110px !important;
			.header--top{
				display: block;
				.eu-osha-logo{
					a{
						img{
							height: 15mm;
							margin-top: 0;
							margin-left: 8px;
						}
					}
				}
			}
			&.hide-header{
				top: 0 !important;
			}
		}
		> a{
			flex: 66%;
			// height: 110px !important;
			.home-cover{
				min-height:auto;
				padding-left: 16px;
				.main-title{
					img{
						height: 3.6rem;
					}
					.lead-title {
						font-size: 2.2rem !important;
					}
				}
			} 
		}
		.breadcrumbs--social--network{
			top: 0;
			padding: 1rem 2rem !important;
			flex: 100%;
			border-top: 1px solid #CCC;
			margin-top: 1.6rem;
		} 
		> div{
			flex: 100%;
		}
	}


	.home-cover{
		border-top: 0px;
		height: auto;
	}
	.carousel--home{
		min-height: auto !important;
		.carousel-indicators{
			display: none;
		}
		.carousel-inner {
			width: 100%;
			padding: 0;
			margin: 0px auto;
			min-height: auto !important;
			position: relative;
			> .carousel-item{
				width: 100% !important;
				min-width: 100%;
				max-width: 100%;
				position: initial!important;
				top: 0!important;
				left: 0!important;
				display: block !important;
				float: none !important;
				min-height: auto;
				height: auto;
				.title--section{
					margin: 1rem 0  !important;
					padding-top: 1rem !important;
				}
				.carousel-caption {
					min-height: auto;
					height: auto;
					position: relative;
				}
			}
		}
	}
	.advice--block{
		top: 0;
		&.advice--block-not-home{
  		padding: 2rem 1.5rem;
		}
		.title--section{
			font-size: 2.4rem;
			font-family: 'Oswald-light';
			margin: 2rem 0;
			padding: 2rem 8rem;
		  border-top: 1px solid $gray-light;
		  border-bottom: 1px solid $gray-light;
			&:before{
				content:'"';
			}
			&:after{
				content:'"';
			}
		}		
	}
	.grid-2{
		margin: 1rem 0;
		.col-img{
			a{
				text-align: center;
				img{
					//width: 50%;
			    margin-top: 2rem;
				}				
			}
		}
		.col-content{
			.title--section{
				margin: 2rem 0;
				padding-top:2rem !important;
			}
		}
	}
	.submenu--level2{
		border: none;
		.submenu--items--wrapper{
			li{
				margin: 1rem 0 !important;
				a{
					padding: 0 !important;
					margin: 0 !important;
					font-size: 3.2rem !important;
    			display: block;
				}
			}
		}
	}
	.level3--wrapper{
		border: none;
		.submenu--level3{
			margin-top: 0 !important;
			.submenu--items--wrapper{
				display: block !important;
				margin: 0 !important;
				li{
					a{
						padding: 0 !important;
						margin: 0 !important;
						font-size: 2.8rem  !important;
						text-align: left;
						display: block;	
						position: relative;
						left: -14px;	
					}
				}
				&.sv, 
				&.de{
					li{
						a{
							position: relative;
							left: -14px;
						}
					}
				}
			}
		}
	}
	.background-main-light{
		.grid-2{
			display: flex;
    		flex-direction:row;
			.col-content{
				flex: 50%;
			}
			.col-img{
				flex: 50%;
			}
		}
	}
	.datafor--dropdown--wrapper{
		.datafor--dropdown--list{
			border: 0;
			.option-title{
				padding: 0.5rem 0.8rem 0 0;
				&:after{
					content: " :";
					font-family: Arial, Helvetica, sans-serif;
					right: 0;
					top: -3px;
				}
			}
		}	
	}
	.background-orange-light{
		.grid-2{
			display: flex;
			flex-direction: row;
			.col-content{
				flex: 50%;
			}
			.col-img{
				flex: 50%;
			}
		}
	}
	.title-section{
		margin: 1.5rem 0;
	}
	.section--page {
		padding: 0;
	}
	.submenu--items--wrapper{
		li{
			display: none;
			a{
				margin: 2rem 0;
				border-bottom:none !important;
				width: 100%;
				text-align: center;
			}
			&.active{
				display: block;
				width: 100%;
			}
		}
	}
	.overview-page{
		.accordion-summary{

		}
		.accordion-content{
			max-height: 100rem;
	    	overflow: visible;
			font-size: 1.6rem;
		}
		h3.accordion-title{
			&:after{
				content:''!important;
			}
		}
	}
	.card--grid{
		.card--item{
			p{
				font-size: 1.6rem !important;
			}
			a{
				height: 6rem !important;
				padding: 0 !important;
			}
			a.icon--card {
			  background-color: #FDEDCC !important;
			  background-size: auto;
			  display: block !important;
			}	
		}
	}
	.datavisualisation-page{
		.section--page{
			margin: 2rem 0;
		}
		.card--grid{
			.card--item{
				a{
					span{
						position: absolute !important;
						width: 100% !important;
						text-align: center !important;
						top: 30% !important;
					}
				}
			}
		}
	} 
	.block--typechart--wrapper{
		margin: 0 0 2rem 0;
		.chart--selection--block{
			margin: 0 0 0 auto;
			li{
				a{
					&.selected-chart{
						border: 4px solid #000;
					}
				}
			}
		}
	}
	.comparisons {
		.block--filter--wrapper{
			display: flex !important;
			justify-content: flex-start !important;
			width: 100% !important;
			margin: 0rem 0 0 !important;
			flex-direction: row !important;
			flex-wrap: wrap;
			.tags:nth-child(1){
				flex: 100%;
			}
			.or-text {
				margin: 0rem 1rem 1rem 0  !important;
			}
			.filter-text{
				margin: 0 1rem 0 0;
				min-width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				font-weight: bold;
				flex-wrap: wrap;
				label{
					width: 100% !important;
					font-weight: bold;
				}
				select, #establishment{
					border: none;
					padding: 0 !important;
					outline: 0px!important;		
				}
				> span{
					font-weight: bold;
	
				}
				&:after{
					content:'' !important;
				}
			}
			.filter-text-button{
				margin-right: 1rem;
				&.country-select{
					select{
						outline: 1px solid #57575A;
						background: #57575A !important;
						color: #FFF !important;
					}
				}
				button{				
					&.active{
						background: $gray !important;
						color: #FFF !important;
						outline: 1px solid #57575A !important;
					}
				}
			}
		}
	}

	.detail-page{
    	margin-top: 0;
		.block--filter--wrapper{
			display: flex !important;
			justify-content: flex-start !important;
			width: 100% !important;
			margin: 0rem 0 0 !important;
			flex-direction: row !important;
			flex-wrap: wrap;
			.tags:nth-child(1){
				flex: 100%;
			}
			.or-text {
				margin: 0.8rem 0 !important;
				width: 20px;
				text-align: center;
			}
			.filter-text{
				margin: 0;
				min-width: initial;
				// width: calc(50% - 20px);
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				font-weight: bold;
				flex-wrap: wrap;
				&.countryComparisonWith{
					display: none !important;
				}
				// border: 1px solid;
				label{
					width: 100% !important;
					font-weight: bold;
					text-transform: uppercase;
				}
				select, #establishment{
					border: 0;
					outline: 0px !important;
					padding: 0;
					color: #CCC !important;
					&.comparisons{
						border-top: 1px solid #CCC;
						padding: 0.8rem !important;
						outline: 0px !important;
						width: 100%;
						background: #f2f2f2 !important;
						color: #000 !important;
					}
				}
				&.active, &.country-items{
					select, 
					#establishment{
						border-top: 1px solid #CCC;
						padding: 0.8rem !important;
						outline: 0px !important;
						background: #f2f2f2 !important;
						color: #000 !important;
						width: 100%;
					}
				}
				> span{
					font-weight: bold;
	
				}
				&:after{
					content:'' !important;
				}
			}
			.filter-text-button{
				margin-right: 1rem;
				&.country-select{
					select{
						outline: 1px solid #57575A;
						background: #57575A !important;
						color: #FFF !important;
					}
				}
				button{				
					&.active{
						background: $gray !important;
						color: #FFF !important;
						outline: 1px solid #57575A !important;
					}
				}
			}
		}
		.detail--page--wrapper {
	    width: 100%;
			.detail--content--section{
				width:100%;
				padding: 0;
				.content--lang--wrapper {
				  position: relative;
				  right: 0;
				  top: 0;
				  width: 100%;
				  text-align: center;
				  margin: 2rem 0;
				}
			}
		}
	}
	svg{
		page-break-inside : avoid !important;
	}

	.national-bar-chart-wrapper {
		padding: 0rem !important;

		.highcharts-container {
			height: auto !important;
		}
		svg {
			padding: 0rem;
			zoom: 0.5 !important
		}
	}

	.european-bar-chart {
		padding:0rem !important;
		svg{
		padding: 0rem;
		zoom:0.5 !important;
		}  
	}

	.national-comparison-chart {
		padding: 0rem !important;
		svg {
			width: 100% !important;
		}
	}

	.pie-chart{
		padding:0rem !important;
		svg{
		padding: 0rem;
		zoom:0.5 !important;
		}
	}
	.comparisons-chart{
		.highcharts-container {
			width: 613px !important;
			svg {
				width: 100% !important;
			}
		}
	}

	.survey--map--block{
		.map--block{
			height: 12cm;
		svg{
			padding: 0rem;
			zoom:0.5 !important;
		}		
		}
	}
	.overview-page{
		.accordion-content{
			padding: 0rem 4rem !important;
		}
	} 

	.footer-elements{
		text-align: center!important;
	}
	.selected--tags-wrapper {
		margin: 0 !important;
		top: -38px;
		left: 100px;
		width: 150px;
	}
	.comparisons{
	    margin-top: 2rem;
		 .detail--page--wrapper{
	 	    width: 100% !important;
	 	    .detail--content--section{
				width: 100% !important;
				padding: 0;
				.selector--page--elements{
					.content--intro--wrapper{
						width: 100% !important;
						.datafor--dropdown--wrapper{
							height: 4rem;
						}
					}
				}
	 	    }
		 }
	}
}


