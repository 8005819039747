.detail-page,
.comparisons {
  position: relative;
  width: 100%;
  display: block;
  margin-top: 5rem;
  .detail--page--wrapper {
    position: initial;
    width: 90%;
    display: flex;
    flex-direction: row;
    .detail--content--section {
      position: initial;
      width: 74%;
      padding: 0 4rem;
      h3:empty {
        display: none;
      }
      position: initial;
        .content--intro--wrapper {
          position: initial;
          width: 92%;
          h1 {
            margin-bottom: 1rem;
          }
          > p {
            //font-style: italic;
            //font-size: 1.4rem;
            .level1-bread {
              color: $black;
            }
            .level2-bread {
              color: $gray;
            }
          }
        }
        .content--intro, .chart--wrapper & {
          .title-question {
            margin-bottom: 1rem;
            margin-top: 3rem;
          }
          .description--question {
            font-style: italic;
            padding: 0 0 2rem 0;
          }
        }
    }
  }
}
.title--card {
  color: $aquamarine;
  text-align: left;
  padding: 1rem 1rem 0px 1rem;
  font-size: 3rem;
}

.national-bar-chart-wrapper {
}

.tags {
  align-self: center;
  span {
    padding: 1rem 1rem 0 0;
    display: inline-block;
  }
}

.social--network--modal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  margin-right: 10rem;
  margin-left: 10rem;
  font-size: 3rem;
}

.export--data--modal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  //margin-right: 10rem;
  //margin-left: 10rem;
}

.dvt-modal-social {
  display: block;
  background: $white;
  width: 70%;
  margin: auto;
  height: 70%;
  border-radius: 6px;
  .modal-wrapper {
    height: auto;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.6rem;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #666;
    }
    .card--block--chart--wrapper {
      .chart--submenu {
        list-style: none;
        display: block;
        justify-content: unset;
        position: relative;
        width: 80%;
        margin: auto;
        padding: 0;
        > li {
          float: right;
          > a {
            width: 3.5rem;
            height: 2.7rem;
            display: block;
            color: $gray-base;
            &.download-button:after {
              content: '\f019';
              font-family: FontAwesome;
            }
          }
        }
      }
      .export-button-modal {
        a {
          padding: 0.8rem 1rem;
          border-radius: 0.3rem;
          background: #f2f2f2;
          color: #000;
          font-size: 1.4rem;
          display: block;
          overflow-y: hidden;
          &:hover {
            color: $white;
            background: $aquamarine;
            .fa-arrow-down {
              top: 0rem;
              -webkit-transition: all 0.8s;
              transition: all 0.8s;
            }
            .fa-picture-o {
              top: 3rem;
              -webkit-transition: all 0.8s;
              transition: all 0.8s;
            }
          }
          .fa-arrow-down {
            position: relative;
            top: -3rem;
            left: 0.3rem;
            -webkit-transition: all 0.8s;
            transition: all 0.8s;
          }
          .fa-picture-o {
            position: relative;
            top: 0rem;
            left: -1rem;
            -webkit-transition: all 0.8s;
            transition: all 0.8s;
          }
        }
      }
      .title--card {
        font-size: 2.8rem;
        margin-top: 0;
        text-align: left;
      }
      .chart--wrapper {
        width: 80%;
        height: auto;
        margin: 10vh auto;
        svg {
          text {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .dvt-chart {
    height: 100% !important;
    width: 100%;
    overflow: hidden;
    border-radius: 0.5em;
    margin: 0;
    position: relative;
    left: 0%;
    top: 0%;

    h3 {
      font-size: 2rem !important ;
      min-height: auto !important;
    }
    .legend-info {
      float: left;
      background: $white;
      width: 100%;
      padding: 0 3rem;
      color: #000;
      text-align: center;
      font-size: 1.4rem;
    }
  }
}

#popUpMessage {
  //background: rgba(0,0,0,0.6);
  background: rgba(37, 173, 175, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  p.title-share {
    font-size: 1.6rem;
    margin: 2rem;
    font-weight: bold;
  }
  .social--network--modal {
    a {
      i {
        cursor: pointer;
      }
      &:hover {
        color: $orange;
      }
    }
  }
  .popUpMessage--exportData--text--block {
    background: $white;
    width: 100%;
    //height: 70vh;
    top: 0;
    margin: 4vh 0vw;
    padding: 4vh 2vw;
    position: relative;
    #panel--url {
      margin: 4rem 0;
    }
    #panel--url {
      .btn-default {
        width: 24rem;
        text-align: center;
        padding: 0.8rem 0;
      }
      #urlTextArea {
        font-style: italic;
        color: $gray-light;
      }
    }
    .export--data--modal {
      > a {
        i {
          font-size: 4rem;
          vertical-align: middle;
          margin-right: 1rem;
        }
        &.active {
          color: $orange !important;
        }
      }
    }
    p {
      font-size: 1.6rem;
      margin: 2rem 0;
    }
    .close-popup {
      bottom: 5vh;
      position: absolute;
      right: 5vw;
    }
  }
}

#panel--url {
  display: flex;
  flex-direction: column;
  .pageUrl {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

#urlTextArea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}
.questions--list--wrapper {
  .filter--questions--group--wrapper {
    > p {
      display: none;
    }
  }
}
.filter--questions--group--wrapper {
  .filter--questions--wrapper {
    .dropdown {
      background: #666;
      padding: 0;
      margin: 0;
      min-height: 12rem;
      .input-group {
        width: 100%;
        display: block;
      }
      .dropdown-toggle,
      .css-yk16xz-control,
      .css-1pahdxg-control {
        margin: 0 3rem !important;
        max-width: 100% !important;
        width: auto;
        border: none;
        padding: 2rem 0;
        overflow: hidden;
        box-shadow: none;
        background: #666;
        border-bottom: 1px solid #FFF;
        border-radius: 0;
        .css-1opnhvy-singleValue{
        }
        .css-1okebmr-indicatorSeparator{
          display: none;
        }
        .css-10lvx9i-Input{
          // visibility: hidden;
        }
      }
      .css-1pahdxg-control{
        overflow: visible;
        position: relative;
        // top: 16px;
        .css-1jrjl0-ValueContainer{
          overflow: visible;
        }
        .css-1hb7zxy-IndicatorsContainer{
           visibility: hidden;
        }
        .css-10lvx9i-Input{
          // visibility: visible;
          width: 110%;
          background: #FFF;
          padding: 0.8rem;
          color: #666;
          position: absolute;
          top: -1.6rem;
          left: 0;
          z-index: 99;
          margin: 0;
          // background: #FFF url(../style/img/magnifying-glass.svg) no-repeat 100.5%;
          // background-size: 2rem;
          &:after{
            content:" ";
            background: #666 url(../style/img/magnifying-glass.svg) no-repeat 3px 1rem;
            background-size: 2rem;
            width: 2.2rem;
            height: 4rem;
            top: 0;
            right: 0;
            display: inline-block;
            position: absolute;
          }
        }
      }
      .css-2b097c-container{
        div[class$="-menu"]{
          background: #666666; 
          border: 0;
          border-radius: 0;
          box-shadow: none;  
          top: 8rem;     
          div[class$="-MenuList"]{
            padding: 0 1.6rem 0 0;
            margin: 1.6rem 3rem;
            box-shadow: none;
            background: #666666;
            div[class$="-option"]{
              border-bottom: 1px dotted #c4c4c4;
              padding: 1.2rem 0.8rem;
              font-size: 1.4rem;
              &:hover{
                cursor: pointer;
                background: #f3f3f3;
              }
            }
            &::-webkit-scrollbar {
              background: #fff;
              box-shadow: none;
              border-radius: 6px;
              width: 0.8rem;
              padding: 0 0.2rem;
              margin: 0.5rem 0;
            }
            &::-webkit-scrollbar-track {
            }
            &::-webkit-scrollbar-thumb {
              background: #444;
              border-radius: 6px;
              width: 0.4rem;
              height: 3rem;
              margin: 0.2rem;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #000;
            }
          }
        }        
      }
    }
  }
  .questions--tree {
    background-color: #e7e8e8;
    padding: 3rem 2rem;
    width: 100%;
    ul {
      list-style: none;
      padding: 0 0 0 1.5rem;
      margin: 0;
      li {
        a {
          font-family: 'OpenSans';
          color: #58585a;
          display: block;
          padding: 1rem 3rem 1rem 0;
          position: relative;
          cursor: pointer !important;
          &.selected {
            font-family: 'OpenSans-Bold';
          }
        }
      }
      &.download--button {
        li {
          a {
            background: #58585a;
            color: #fff;
            font-family: 'OpenSans-bold';
            padding: 1.5rem;
            position: relative;
            margin: 0;
            &:after {
              content: url(../style/img/download-icon.png);
              background: none;
              position: absolute;
              right: 1.5rem;
            }
            &:hover {
              opacity: 0.6;
              &:after {
                content: url(../style/img/download-icon.png);
              }
            }
          }
        }
      }
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        margin-bottom: 1rem;
        > span {
          display: block;
          > a {
            display: block;
            color: #58585a;
            background: #d7d8d9;
            border: 1px solid #58585a;
            padding: 1.5rem 3rem 1.5rem 1.5rem;
            font-size: 1.4rem;
            font-family: 'OpenSans-bold';
            &:after {
              content: url(../style/img/dropdown-arrow-down.svg);
              width: 2rem;
              height: 2rem;
              position: absolute;
              right: 1rem;
            }
            &:hover {
              text-decoration: none !important;
              background: #efefef;
            }
            &:focus {
              text-decoration: underline;
            }
          }
        }
        > ul {
          display: none;
          &.open {
            display: block;
            > ul {
              display: block;
            }
          }
          > li {
            margin-bottom: 1rem;
            > span {
              > a {
                font-size: 1.4rem;
                &:hover {
                  text-decoration: none !important;
                }
                &:focus {
                  text-decoration: underline;
                }
              }
            }
            > ul {
              display: none;
              > li {
                border-bottom: 1px solid #c8c9c9;
                > span {
                  > a {
                    font-size: 1.4rem;
                  }
                }
              }
            }
            &.has-level3 {
              position: relative;
              > span {
                a:after {
                  content: url(../style/img/dropdown-arrow-down.svg);
                  width: 2rem;
                  height: 2rem;
                  position: absolute;
                  right: 1rem;
                  top: 1rem;
                  opacity: 0.6;
                }
                a:focus {
                  text-decoration: underline;
                }
              }
            }
            &.open {
              position: relative;
              > span {
                a {
                  font-family: 'OpenSans-Bold';
                  &:after {
                    content: url(../style/img/dropdown-arrow-up.svg);
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                  }
                }
              }
              > ul {
                display: block;
                margin: 0rem;
                border-left: 1px dotted #666;
                > li {
                  > a {
                    font-family: 'OpenSans';
                    &:after {
                      content: '';
                    }
                  }
                }
              }
            }
          }
        }
        &.open {
          display: block;
          position: relative;
          > ul {
            display: block;
          }
          > span {
            a {
              font-family: 'OpenSans-Bold';
              &:after {
                content: url(../style/img/dropdown-arrow-up.svg);
                width: 2rem;
                height: 2rem;
                position: absolute;
                right: 1rem;
                top: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.questions--list--wrapper {
  position: relative;
  z-index: 10;
  padding: 0;
  width: 26%;
}
.content--lang--wrapper {
  position: absolute;
  right: 10rem;
  top: 0;
  width: 26rem;
  text-align: right;
  margin: 0 0 2rem 0;
  form.compare--block--form {
    label {
      font-family: 'OpenSans';
      font-size: 1rem;
      width: 14rem;
    }
    > div {
      position: relative;
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        font-family: 'OpenSans-Bold';
        font-size: 1.7rem;
        text-align-last: right;
        direction: rtl;
        background: url(../style/img/dropdown-arrow-down.svg);
        background-repeat: no-repeat;
        background-position: 100%;
        background-size: 2rem 2rem;
        padding-right: 2.5rem;
        width: 100% !important;
        &::-ms-expand {
          display: none;
        }
        /*
        &:focus{
          background: url(../style/img/dropdown-arrow-up.svg);
          background-repeat: no-repeat;
          background-position: 100%;
        }
        */
        option {
          direction: ltr;
          font-family:'OpenSans';
          font-size: 1.4rem;
          &:checked {
            color: $aquamarine;
          }
        }
      }
      &:after {
        //content: "\f107";
        font-family: FontAwesome;
        font-weight: normal;
        font-size: 2rem;
        height: auto;
        padding: 1rem;
        position: absolute;
        top: -1rem;
        right: 0;
      }
    }
  }
}
.block--filter--wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0rem 0 0;
  .note-select {
    color: $aquamarine;
  }
  .or-text {
    margin: 4.2rem 1rem 2rem 0;
  }
  .filter-text {
    margin: 0 1rem 0 0;
    min-width: 33%;
    position: relative;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      margin: 1rem 0;
      padding: 1rem 3rem 1rem 1rem;
      font-size: 1.6rem;
      outline: 1px solid $aquamarine;
      option {
        background: $white;
        color: $gray;
        font-family: 'OpenSans', arial;
      }
      &.disabled {
        background: $gray-light !important;
        outline: 1px solid $gray-light !important;
        cursor: not-allowed;
        color: $white !important;
        opacity: 0.6;
        width: 100% !important;
      }
      &:focus {
        outline: 1px solid $aquamarine !important;
        outline-offset: 0 !important;
      }
    }
    label {
      display: block;
      max-width: 100%;
      margin: 0;
      font-weight: normal;
      &.disabled {
        visibility: hidden !important;
        //width: 1rem;
        //height:2.3rem;
        //display: none !important;
      }
    }
    &:after {
      content: '\f107';
      font-family: FontAwesome;
      font-weight: normal;
      font-size: 2rem;
      height: auto;
      padding: 1rem;
      position: absolute;
      pointer-events: none;
    }
    &.disabled {
      width: auto !important;
      min-width: auto;
      &:after {
        content: '' !important;
      }
    }
    &.activity-sector {
      select {
        width: 100%;
        background: $white;
        color: $aquamarine;
        position: relative;
        /*
        &.disabled{
          background: $gray-light !important;
          outline:1px solid $gray-light;
          cursor: not-allowed;
          outline: none;
          color: $white;
          opacity: 0.6;
          width: 100%;
        }
        */
      }
      &:after {
        color: $aquamarine;
        right: 0;
        top: 3rem;
      }
    }
    &.activity-sector-pie-chart {
      select {
        width: 100%;
        background: $aquamarine;
        color: $white;
        position: relative;
      }
      &:after {
        color: $white;
        right: 0;
        top: 1rem;
      }
    }
    &.establishment-size {
      select {
        width: 100%;
        background: $white;
        color: $aquamarine;
        position: relative;
        /*
        &.disabled{
          margin: 0.8rem 0;
          padding:1.1rem 3rem 1.1rem 1rem;
          background: $gray-light !important;
          color: $white!important;
          outline:none!important;
          cursor: not-allowed;
          opacity: 0.6;
          width: 100%;
        }
        */
      }
      &:after {
        color: $aquamarine;
        right: 0;
        top: 3rem;
      }
      &.long1 {
        min-width: 32rem;
      }
      &.long2 {
        min-width: 23rem;
      }
      &.long3 {
        min-width: 19rem;
      }
    }
    &.establishment-size-pie-chart {
      select {
        width: 100%;
        background: $white;
        color: $aquamarine;
        position: relative;
      }
      &:after {
        color: $aquamarine;
        right: 0;
        top: 1rem;
      }
    }
    &.country-items {
      min-width: auto;
      select {
        width: auto;
        background: $aquamarine;
        color: $white;
        position: relative;
        &.comparisons {
          //margin: 1.1rem 0 0;
        }
        &::-ms-expand {
          display: none;
        }
        option {
          background: $white;
          color: $gray;
        }
      }
      &:after {
        color: $white;
        right: 0;
        top: 3rem;
      }
      &.disabled {
        select {
          background: $gray-light !important;
          outline: 1px solid $gray-light !important;
          cursor: not-allowed;
          color: $white;
          opacity: 0.6;
        }
      }
    }
    &.countryComparisonWith {
      display: none;
    }
    &.active {
      select {
        background: $aquamarine;
        color: $white;
      }
      &:after {
        color: $white;
        right: 0;
        top: 3rem;
      }
    }
  }
  .filter-text-button {
    width: auto;
    margin-right: 2rem;
    position: relative;
    button {
      width: auto;
      margin: 1rem 0;
      padding: 1rem 2rem;
      background: $white;
      outline: 1px solid $aquamarine !important;
      color: $aquamarine;
      border: none;
      position: relative;
      &.active {
        width: auto;
        margin: 1rem 0;
        padding: 1rem 2rem;
        background: $aquamarine;
        color: $white;
        position: relative;
        border: none;
        pointer-events: none;
      }
    }
    span {
      display: inline-block;
      padding: 0 0 0 2rem;
    }
    select#datafor {
      //margin: 3.1rem 0 0;
    }
    select#comparewith {
      margin: 3.1rem 0 0;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      margin: 1rem 0;
      padding: 1rem 3rem 1rem 1rem;
      font-size: 1.6rem;
      outline: 1px solid $aquamarine;
      background: $aquamarine;
      color: $white;
      &.comparisons {
        margin: 3.1rem 0 0;
      }
      &::-ms-expand {
        display: none;
      }
      option {
        background: $white;
        color: $gray;
      }
      &:disabled {
        background: #b1b3b4 !important;
        outline: 1px solid #b1b3b4 !important;
        cursor: not-allowed;
      }
    }
    &.country-select:after {
      content: '\f107';
      font-family: FontAwesome;
      font-weight: normal;
      font-size: 2rem;
      height: auto;
      padding: 1rem;
      position: absolute;
      pointer-events: none;
      color: $white;
      right: 0;
      top: 0.7rem;
    }
    &:nth-child(1) {
    }
    &:nth-child(2) {
      button {
        width: auto;
        margin: 1rem 0;
        padding: 1rem 2rem;
        background: $white;
        outline: 1px solid $aquamarine !important;
        color: $aquamarine;
        border: none;
        position: relative;
        &.active {
          width: auto;
          margin: 1rem 0;
          padding: 1rem 2rem;
          background: $aquamarine ;
          color: $white;
          position: relative;
          border: none;
        }
        &:disabled {
          pointer-events: none !important;
        }
      }
      &.exception {
        cursor: not-allowed !important;
        button {
          color: #b1b3b4 !important;
          outline: 1px solid #b1b3b4 !important;
        }
      }
    }
    &:nth-child(3) {
    }
  }
}
.filters--and--typechart {
  display: flex;
  align-items: center;
  .countryComparisonWith {
    min-width: auto;
    position: relative;
    top: -1.5rem;
    label {
      display: block;
      max-width: 100%;
      margin: 0;
      font-weight: normal;
      &.disabled {
        visibility: hidden !important;
        width: 1rem;
        height: 2.2rem;
        //display: none !important;
      }
    }
    select {
      width: auto;
      margin: 0.5rem 0 0;
      padding: 1.1rem 2rem;
      background: $aquamarine;
      color: $white;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      &::-ms-expand {
        display: none;
      }
      option {
        background: $white;
        color: $gray;
      }
    }
    &:after {
      content: '\f107';
      font-family: FontAwesome;
      font-weight: normal;
      font-size: 2rem;
      height: auto;
      padding: 1rem;
      position: absolute;
      color: $white;
      right: 0;
      top: 2.5rem;
      pointer-events: none;
    }
  }
}
.note-select {
  color: $aquamarine;
}
.block--typechart--wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .filter-text {
    position: relative;
    display: flex;
    label,  > span {
      font-weight: normal;
      width: auto;
      text-align: left;
      padding: 0.9rem 0.5rem 0.8rem 0;
      margin: 0rem 0;
      flex: 1 1 auto;
    }
    select,
    select:focus {
      flex: 1 1 auto;
      //width:90%;
      max-width: 26rem;
      margin: 0rem 0 0rem auto;
      padding: 0rem 3rem 0rem 1.3rem;
      background: #fff;
      outline: 1px solid $gray-light !important;
      outline-offset: 0 !important;
      border: 0;
      color: $gray;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
    }
    &:after {
      content: '\f107';
      font-family: 'FontAwesome';
      font-weight: normal;
      font-size: 2rem;
      height: auto;
      padding: 0 1rem;
      color: $gray-light;
      position: absolute;
      pointer-events: none;
      top: 0.7rem;
      right: 0;
    }
  }
  .chart--selection--block {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0;
    li {
      margin: 0 0.5rem;
      cursor: pointer;
      a {
        display: block;
        border: 1px solid $gray-light;
        width: 4rem;
        height: 4rem;
        padding: 0rem;
        overflow: hidden;
        &:before {
          display: block;
          width: 4rem;
          height: 4rem;
        }
        &:hover {
          border: 1px solid $aquamarine;
        }
        &.selected-chart {
          border: 1px solid $aquamarine;
        }
      }
      &:nth-child(1) {
        a {
          &:before {
            content: url(../style/img/bar-eu-map-icon.svg);
          }
          &:hover {
            border: 1px solid $aquamarine-light;
            &:before {
              content: url(../style/img/bar-eu-map-icon-hover.svg);
              background: $aquamarine-light;
            }
          }
          &.selected-chart {
            &:before {
              content: url(../style/img/bar-eu-map-icon-active.svg);
              background: $orange-light;
            }
            &:hover {
              border: 1px solid $aquamarine-light;
              &:before {
                content: url(../style/img/bar-eu-map-icon-hover.svg);
                background: $aquamarine-light;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        a {
          &:before {
            content: url(../style/img/bar-chart-icon.svg);
          }
          &:hover {
            border: 1px solid $aquamarine-light;
            &:before {
              content: url(../style/img/bar-chart-icon-hover.svg);
              background: $aquamarine-light;
            }
          }
          &.selected-chart {
            &:hover {
              border: 1px solid $aquamarine-light;
              &:before {
                content: url(../style/img/bar-chart-icon-hover.svg);
                background: $aquamarine-light;
              }
            }
            &:before {
              content: url(../style/img/bar-chart-icon-active.svg);
              background: $orange-light;
            }
          }
        }
      }
      &:nth-child(3) {
        a {
          &:before {
            content: url(../style/img/bar-comparision-icon.svg);
          }
          &:hover {
            border: 1px solid $aquamarine-light;
            &:before {
              content: url(../style/img/bar-comparision-icon-hover.svg);
              background: $aquamarine-light;
            }
          }
          &.selected-chart {
            &:hover {
              border: 1px solid $aquamarine-light;
              &:before {
                content: url(../style/img/bar-comparision-icon-hover.svg);
                background: $aquamarine-light;
              }
            }
            &:before {
              content: url(../style/img/bar-comparision-icon-active.svg);
              background: $orange-light;
            }
          }
        }
        &.exception {
          //pointer-events: none;
          cursor: not-allowed !important;
          &:hover {
            a {
              border: 1px solid #b1b3b4;
              &:before {
                background: #fff;
                content: url(../style/img/bar-comparision-icon.svg);
              }
            }
          }
        }
      }
      &:nth-child(4) {
        a {
          &:before {
            content: url(../style/img/bar-comparision2-icon.svg);
          }
          &:hover {
            border: 1px solid $aquamarine-light;
            &:before {
              content: url(../style/img/bar-comparision2-icon-hover.svg);
              background: $aquamarine-light;
            }
          }
          &.selected-chart {
            &:hover {
              border: 1px solid $aquamarine-light;
              &:before {
                content: url(../style/img/bar-comparision2-icon-hover.svg);
                background: $aquamarine-light;
              }
            }
            &:before {
              content: url(../style/img/bar-comparision2-icon-active.svg);
              background: $orange-light;
            }
          }
        }
      }
      &:nth-child(5) {
        margin-right: 0;
        a {
          &:before {
            content: url(../style/img/bar-pie-icon.svg);
          }
          &:hover {
            border: 1px solid $aquamarine-light;
            &:before {
              content: url(../style/img/bar-pie-icon-hover.svg);
              background: $aquamarine-light;
            }
          }
          &.selected-chart {
            &:before {
              content: url(../style/img/bar-pie-icon-active.svg);
              background: $orange-light;
            }
          }
        }
        &.exception {
          //pointer-events: none;
          cursor: not-allowed !important;
          &:hover {
            a {
              border: 1px solid #b1b3b4;
              &:before {
                background: #fff;
                content: url(../style/img/bar-pie-icon.svg);
              }
            }
          }
        }
      }
      &:nth-child(6) {
        a {
          width: auto;
          height: 4rem;
          text-align: center;
          padding: 1.1rem;
          font-size: 1.3rem;
          color: $gray-light;
          &:hover {
            background: $aquamarine-light;
            color: $aquamarine;
          }
          &.selected-chart {
            //background: $orange-light;
            //color: $orange;
            background: $aquamarine-light;
            color: $aquamarine;
          }
        }
      }
      .no-event {
        display: none;
      }
    }
  }
  a#eu-only-mobile {
    display: none;
    border: 1px solid $gray-light;
    overflow: hidden;
    margin: 0 0.5rem;
    width: auto;
    height: 4rem;
    text-align: center;
    padding: 1.1rem;
    font-size: 1.3rem;
    color: $gray-light;
    &:before {
      display: block;
      width: 4rem;
      height: 4rem;
    }
    &:hover {
      border: 1px solid $aquamarine;
    }
    &.selected-chart {
      border: 1px solid $aquamarine;
      background: $aquamarine-light;
      color: $aquamarine;
    }
  }
}

.highcharts-container {
  width: 100% !important;
  text-align: center !important;
}
.european-map {
  margin-top: 1rem;
  .map--block{
    .highcharts-container{
      svg{
        path{
          stroke: #FFF;
          stroke-width: 2;
          &:hover{
            stroke: #666;
            fill: #d6e9ea;
            stroke-width: 1;
          }
          &[fill="#F0F0F0"]{
            stroke: #CCC;
            stroke-width: 1;
            pointer-events: none;
          }
        }
      }
    }
  }
}
.european-bar-chart {
  padding: 4rem;
  .highcharts-container {
    // width: 100% !important;
    // text-align: center !important;
    svg {
      // width: 94%;
      // position: absolute;
      // left: 6%;
      // //height: 100%;
    }
  }
}
.pie-chart{
  .highcharts-container{
    // height: 490px !important;
    svg{
      // position: absolute;
      // left: 0;
      // top: -18rem;
    }
  } 
}
ul.question--by--question {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  li {
    a {
      font-size: 1.3rem;
      display: block;
      width: 8rem;
      height: 12rem;
      background: #666;
      color: #fff !important;
      text-align: center;
      cursor: pointer;
    }
    &:nth-child(1) {
      a {
        left: 0;
        &:before {
          content: url(../style/img/prev-question.svg);
          width: 100%;
          display: block;
          padding: 1.5rem 0;
        }
      }
    }
    &:nth-child(2) {
      a {
        right: 0;
        &:before {
          content: url(../style/img/next-question.svg);
          width: 100%;
          display: block;
          padding: 1.5rem 0;
        }
      }
    }
  }
}
.export--button--wrapper {
  margin: 4rem 0;
  text-align: center;
  button {
    display: inline-flex;
    span {
      i {
        margin-left: 1rem;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .detail-page,
  .comparisons {
    .block--filter--wrapper {
      flex-wrap: wrap;
      &.national-comparisons-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .filter-text {
        margin: 0 1rem 0 0;
        width: 33%;
        &.country-items {
          width: auto !important;
        }
      }
      .or-text {
        margin: 4rem 1rem 2% 0;
      }
      .filter-text-button {
        min-width: auto;
        width: auto;
        margin: 0 0.5rem 0 0;
      }
      .filter-text,
      .filter-text-button {
        select {
          width: 100%;
          border-radius: 0 !important;
          max-width: 100%;
        }
      }
      .filter-national-comparisons {
        width: 45%;
        flex-basis: 45%;
        //margin: 0;
      }
      .countryComparison {
        flex-basis: 46% !important;
        margin: 0;
      }
      .countryComparisonWith {
        flex-basis: 46.5% !important;
        margin: 0 0 0 4%;
        display: block;
      }
      .block--typechart--wrapper {
        width: calc(80% + 6rem);
      }
      &.bg {
        .tags {
          width: 100%;
          text-align: center;
        }
        .filter-text-button {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
      &.en_1 {
      }
    }
    .form--full--items {
      .filter-text {
        flex-basis: 100%;
      }
      .or-text {
        margin: 1rem 1rem 1.6rem 0;
      }
      .countryComparisonWith {
        margin: 0 0 0 auto;
      }
    }
    .filters--and--typechart {
      .countryComparisonWith {
        display: none;
      }
    }
  }
  .european-bar-chart {
    padding: 0rem;
  }
  .chart--section {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .filter--questions--group--wrapper {
    .questions--tree {
      width: 130%;
      position: relative;
      left: -30%;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .detail-page,
  .comparisons {
    margin-top: 0;
    .detail--page--wrapper {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      .detail--content--section {
        width: 100%;
        padding: 0 2rem;
        position: relative;
        .selector--page--elements {
          position: relative;
          padding-top: 9rem;
          .content--intro--wrapper {
            width: 100%;
          }
        }
      }
      .questions--list--wrapper {
        width: 100%;
      }
    }
    .block--filter--wrapper {
      .countryComparison {
        display: block;
        flex-basis: 45.2% !important;
        margin: 0;
      }
      .countryComparisonWith {
        display: block;
        flex-basis: 45.2% !important;
        margin: 0 0 0 3rem;
      }
    }
    .filters--and--typechart {
      .block--typechart--wrapper {
        width: 100%;
      }
      .countryComparisonWith {
        display: none;
      }
    }
  }


  .content--lang--wrapper {
    right: 2rem;
  }
  .questions--list--wrapper {
    .filter--questions--group--wrapper {
      > p {
        font-size: 1.8rem;
        font-family: 'OpenSans-Bold';
        background-color: #e7e8e8;
        padding: 3rem 2rem;
        margin: 0;
        display: block;
        &:hover {
          cursor: pointer;
        }
        &:before {
          content: url(../style/img/select-question-close.svg);
          display: inline-block;
          width: 4rem;
          height: 4rem;
          float: right;
          position: relative;
          top: -0.5rem;
        }
      }
    }
    &.open {
      .filter--questions--group--wrapper {
        > p {
          &:before {
            content: url(../style/img/select-question.svg);
          }
        }
        .filter--questions--group {
          display: none;
          > ul {
          }
        }
      }
    }
  }
  ul.question--by--question {
    position: relative;
    width: 100%;
    margin: 4rem 0;
    li {
      a {
        width: auto;
        padding: 0 1rem;
        height: auto;
        &:before,
        &:after {
          width: 7rem !important;
          display: inline-block !important;
          vertical-align: middle !important;
          padding: 1.5rem 0;
          height: 7.2rem;
        }
      }
      &:nth-child(1) {
        margin-left: -2rem;
        a {
          &:before {
            margin-left: 0;
          }
          &:after {
            content: '';
            display: none !important;
          }
        }
      }
      &:nth-child(2) {
        margin-right: -2rem;
        a {
          &:before {
            content: '';
            display: none !important;
          }
          &:after {
            content: url(../style/img/next-question.svg);
          }
        }
      }
    }
  }
  .block--filter--wrapper {
    .filter-text {
      min-width: 22%;
    }
    .filter-text,
    .filter-text-button {
      select {
        width: 100%;
        border-radius: 0 !important;
        max-width: 100% !important;
      }
    }
  }
  .css-1pahdxg-control{
    .css-1hb7zxy-IndicatorsContainer{
      visibility: hidden;
   }
    .css-10lvx9i-Input{
      width: 105% !important;
    }
  }
  .block--typechart--wrapper {
    .chart--selection--block {
      //padding-right: 5%;
    }
  }
  .content--lang--wrapper {
    margin: 2rem 0;
    form.compare--block--form {
      label {
      }
      > div {
        position: relative;
        select {
        }
      }
    }
  }
  .detail--content--section {
    #popUpMessage {
      .popUpMessage--text--block {
        width: 100%;
        height: auto;
        margin: 5vh 0vw;
        padding: 5vh 0vw;
        .social--network--modal {
          margin-right: 0rem;
          margin-left: 0rem;
        }
      }
      .popUpMessage--exportData--text--block {
        .export--data--modal {
          > a {
            display: flex;
            text-align: left;
          }
        }
      }
      #btnSocial {
        &.btn-default {
          white-space: normal;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-page,
  .comparisons {
    #popUpMessage {
      .dvt-modal-social {
        display: block;
        background: #fff;
        width: 90%;
        margin: auto;
        height: 85%;
        border-radius: 6px;
      }
      .popUpMessage--exportData--text--block {
        background: #fff;
        width: 100%;
        height: 100%;
        margin: 5vh 0;
        padding: 5vh 0;
        p {
          margin: 2rem 0;
        }
        #panel--url {
          padding: 4rem 2rem;
        }
      }
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1250px) {
  .sort-by {
    flex-direction: column;
    top: -2rem;
    select,
    select:focus {
      padding: 0.9rem 3rem 0.9rem 1.3rem !important;
      margin: 0 !important;
    }
    &:after {
      top: auto !important;
      bottom: 0.7rem;
    }
  }
  .block--typechart--wrapper {
    .chart--selection--block {
      margin-top: 1.9rem;
    }
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .block--filter--wrapper {
    .filter-text-button {
      &.country-select {
        //width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .detail-page,
  .comparisons {
    .detail--page--wrapper {
      .detail--content--section {
        .selector--page--elements {
          position: relative;
          padding-top: 7rem;
          .content--lang--wrapper {
            position: absolute;
            right: 0;
            top: 0;
            width: 26rem;
            text-align: right;
            form.compare--block--form {
              text-align: right;
            }
          }
        }
      }
    }
  }
  .block--typechart--wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    .sort-by {
      width: auto;
      margin: 0 0 4rem 0;
      justify-content: flex-start;
      label {
        flex: 0 0 auto;
      }
      select,
      select:focus {
        max-width: initial !important;
        flex: 0 0 auto;
        margin: 0;
      }
    }
    .chart--selection--block {
      display: block;
      padding: 0 !important;
      width: 100%;
      margin-left: 0;
      height: 5rem;
      overflow: hidden;
      position: relative;
      li {
        display: none;
        margin: 0 0.5rem 2rem 0.5rem;
        a {
          overflow: visible;
          width: auto;
          border: none;
          display: flex;
          &:hover {
            border: none !important;
          }
          &:before {
            display: inline-block !important;
            vertical-align: middle;
            width: 4rem;
            height: 4rem;
            border: 1px solid $gray-light;
          }
          &.selected-chart {
            border: none !important;
            &:before {
              border: 1px solid $gray-light !important;
            }
          }
          span {
            display: inline-block !important;
            padding: 1.1rem;
            font-size: 1.3rem;
            color: $gray;
          }
        }
        &.selected-chart {
          display: block;
          .no-event {
            background: transparent;
            display: block;
            width: 100%;
            position: absolute;
            height: 5rem;
          }
          &:after {
            content: '\f107';
            font-family: FontAwesome;
            color: $aquamarine;
            font-weight: normal;
            font-size: 3rem;
            height: auto;
            padding: 0rem 1rem;
            position: absolute;
            top: 0;
            right: 1rem;
          }
        }
      }
      li:nth-child(6) {
        display: none !important;
      }
      &.open {
        height: auto;
        overflow: visible;
        li {
          display: block;
          &.selected-chart:after {
            content: '\f106';
          }
        }
      }
    }
    a#eu-only-mobile {
      display: block;
      width: auto;
      margin-left: auto;
      margin-right: 0;
      margin-top: 2rem;
    }
  }
  // .dvt-map-tooltip {
  //   width: 90% !important;
  //   left: 5% !important;
  //   &:after {
  //     display: none;
  //   }
  // }
  .detail-page {
    .block--filter--wrapper {
      flex-wrap: wrap;
      .or-text {
        margin: 1rem 0;
        display: block;
        text-align: center;
        width: 100%;
      }
      .filter-text-button {
        min-width: auto;
        width: auto;
        margin-right: 1rem;
      }
      .filter-text {
        min-width: auto;
        flex-basis: auto;
        width: 100% !important;
        margin-right: 0rem;
      }
      .filter-text,
      .filter-text-button {
        select {
          width: 100%;
          &#datafor {
            margin: 1rem 0 1rem;
          }
          &#comparewith {
            margin: 1rem 0 1rem 0;
          }
        }
        label.disabled {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .export--button--wrapper {
    button {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      span {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .block--filter--wrapper {
    .filter-text-button {
      button {
        width: 100% !important;
      }
    }
  }
  .european-bar-chart {
    padding: 4rem 0 0;
  }
  #popUpMessage {
    .popUpMessage--exportData--text--block {
      width: 100%;
      margin: 6vh 0vw;
      padding: 0vh 2vw;
      > p {
        font-size: 1.6rem;
        margin: 0rem 0 2rem;
      }
      .export--data--modal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > a {
          margin: 2rem 0;
          width: 50%;
          text-align: left;
        }
      }
      #panel--url {
        #urlTextArea {
          height: auto;
          font-size: 1.2rem;
          font-style: italic;
          color: $gray-light;
        }
        .pageUrl {
          flex-direction: column;
          .btn-default {
            > span {
              width: auto;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .block--typechart--wrapper {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    .filter-text {
      flex-direction: column;
      width: 100%;
      label, > span {
        text-align: left;
        width: 100%;
        padding: 1rem 0;
      }
      select,
      select:focus {
        width: 100%;
        padding: 1rem 1rem 1rem 1.3rem;
      }
      &:after {
        top: 5rem;
      }
    }
  }
  .national-bar-chart-wrapper{
    .chart--block{
      span.highcharts-title {
        font-size: 2.2rem !important;
        text-align: left;
        width: 100% !important;
        padding: 0 !important;
        display: block !important;
        left: 0 !important;
      }
    }
  }

}

@media only screen and (max-width: 620px) {
  .detail--content--section {
    #popUpMessage {
      .popUpMessage--text--block {
        width: 100%;
        height: auto;
        margin: 5vh 0vw;
        padding: 5vh 0vw;
        .social--network--modal {
          margin-right: 0rem;
          margin-left: 0rem;
        }
      }
      #btnSocial {
        &.btn-default {
          white-space: normal;
        }
      }
    }
  }
  .block--typechart--wrapper .sort-by {
    width: 100%;
  }
  hr {
    margin-top: 1.1rem;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 375px) {
  .breadcrumbs--social--network {
    .breadcrumbs {
      margin: 1.5rem 0 0rem;
    }
  }
  .block--filter--wrapper {
    .filter-text {
      &.establishment-size {
        &.long1 {
          min-width: initial;
        }
        &.long2 {
          min-width: initial;
        }
        &.long3 {
          min-width: initial;
        }
      }
    }
  }
  .detail-page,
  .comparisons {
    .filter-text,
    .filter-text-button {
      margin-right: 0 !important;
      width: 100%;
      button {
        min-width: auto;
        width: 100% !important;
      }
    }
    .tags span {
      padding: 1rem 1rem 0 0;
      display: block;
      text-align: center;
    }
    #popUpMessage {
      .popUpMessage--exportData--text--block {
        .export--data--modal {
          > a {
            width: 100%;
          }
        }
      }
    }
  }
  .block--typechart--wrapper {
    flex-wrap: wrap;
    .chart--selection--block {
      width: 100% !important;
      li {
        margin: 0 0rem 2rem 0rem;
        &.selected-chart {
          &:after {
            right: 0;
          }
        }
        a {
          span {
            padding: 1.1rem 2rem 1.1rem 0.5rem;
          }
        }
      }
    }
    a#eu-only-mobile {
      width: 100% !important;
      margin: 1rem 0 2rem 0;
    }
  }
  ul.question--by--question {
    li {
      a {
        &:before,
        &:after {
          width: 5rem !important;
          display: inline-block !important;
          vertical-align: middle !important;
          padding: 2rem 0 !important;
          height: 7.2rem;
        }
      }
    }
  }
  .european-bar-chart {
    padding: 0rem 0 0;
  }
  div[query='getPieChartData'] {
    svg {
      height: 400px !important;
    }
  }
  .footer-elements {
    .copyright {
      line-height: 2.4rem !important;
      a {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 325px) {
  .block--filter--wrapper {
    .filter-text {
      &.establishment-size {
        &:after {
          top: 5rem;
        }
      }
    }
  }
}

@media only screen and (max-height: 767px) {
  .dvt-modal-social .modal-wrapper {
    height: 100vh;
    overflow: auto;
  }
}

@media screen and (max-height: 767px) {
  .dvt-modal-social {
    height: 95%;
    .pull-right {
      position: relative;
      z-index: 99999;
    }
  }
  #popUpMessage {
    .popUpMessage--exportData--text--block {
      margin: 6% 0vw !important;
    }
  }
}

td.ccc-tt-dimLabel {
  display: none;
}
.tipsy {
  font-size: 1.4rem !important;
  font-family: 'OpenSans', sans-serif !important;
}
.popUpMessage--exportData--text--block {
  .export--data--modal {
    > a {
      display: flex;
      text-align: left;
    }
  }
}

.map--gauss--block {
  svg {
    > g {
      > g {
        > g:nth-child(2) {
          > g:nth-child(3) {
            > g {
              &:nth-child(1) {
                text {
                  text-anchor: start !important;
                }
              }
              &:nth-child(5) {
                text {
                  text-anchor: end !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
