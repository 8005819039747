/*** FOOTEER **/

  /*** FOOTEER **/
  .logos-footer{
    background: #F0F0F0;
    display: none;
    .footer-logotypes{
      text-align: center;
      list-style: none;
      display: flex;
      flex-direction: row; 
      flex-wrap: nowrap;
      justify-content: space-between;
      li{
        a{
          img{
            height: 8rem;
            margin:1.5rem;
            padding: 0;

          }
        }
      }
    }
  }
  footer {
    background:$aquamarine-dark;
    position: relative;
    z-index: 10;
    a {
      color: $white;
    }
    .footer-elements {
      color: $white;
      margin: 0 auto;
      width: 100%;
      padding:1.5rem 0 0;
      font-weight: 700;        
      float: none;
      display: flex;
      flex-direction: column;
      @media (min-width: 1600px) {
        width: 1600px;
        margin: auto;
      }
      .footer-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .label {
          font-size: 1.44rem;
          font-family:'OpenSans-Semibold';
        }
        .footer-social-network{
          margin: 1.5rem 0 1.5rem 0.5rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: auto;

          li {
            padding: 0 1.2rem;
            a{
              font-family:'OpenSans-Semibold';
              color: $white;
              font-size:2.4rem;
              display: inline;
              &:hover{
                text-decoration:underline;
                opacity: 0.6;
              }
            }
          }
        }
      }
      .footer-bottom {
        margin: 0 0 1.5rem;
        display: flex;
        justify-content: space-between;
        .copyright {
          display: inline-block;
          margin: 0;
          line-height: 40px;
          font-family: "OpenSans-Semibold";
          font-weight: 600;
          font-size: 1.44rem;
          color: #FFF;
          width: auto;
          a {
            color:$white;
            display: inline-block;
            &::first-letter {
              text-transform: uppercase;
            }
            &:hover{
              text-decoration:underline;
            }
          }
        }

        .footer-nav-accessibility {
          margin:8px 0 0;
          li {
            display: inline-block;
            list-style-type: none;
            padding: 0;
            a{
              font-family: "OpenSans-Semibold";
              font-weight: 600;
              font-size: 1.44rem;
              color: #FFF !important;
              display: inline-block;
              padding: 0px 7.5px;
              &:hover{
                text-decoration:underline;
              }
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    } 
  }


  @media (max-width: 1024px) {
    footer{
      .copyright {
  
      }
      .footer-nav-accessibility {
  
      }
      .footer-social-network{
        margin: 1.6rem 0;
        width: 100%;
        justify-content: center;
      }
    }
  }


  @media only screen and (max-width: 1024px) {
    footer{
      .footer-elements {
        text-align: center;
        .footer-social-network{
          justify-content: flex-start !important;
        }	
        .footer-bottom {
          display: flex;
          flex-direction: column-reverse;
          text-align: left;
          .copyright {
    
          }
          .footer-nav-accessibility {
    
          }			
        }	
      }
    }
  }

  @media only screen and (max-width: 767px)  { 
    footer{
      top: 0rem;
      position: relative;
      .footer-elements {
        text-align: center;
        width: 80%;
        margin: 0;
        padding: 2.5rem 0.5rem;
        .footer-top {
          flex-direction: column;
          align-items: flex-start;
          .label {
            font-size: 1.44rem;
          }
          .footer-social-network{
            margin:1.5rem 0 1.5rem 0.5rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: auto;

            li{
              padding: 0 1.2rem;
              a{
                font-family:'OpenSans-Semibold';
                color: $white;
                font-size:2.4rem;
                &:hover{
                  opacity: 0.6;
                }
              }
            }
          }			
        }
        .footer-bottom {
          flex-direction: column-reverse;
          margin: 0 1.5rem;
          .copyright {
            margin: 2.6rem 0 0;
            line-height: 2.2rem;
            > a {
              display: block;
              width: 100%;
            }
          }
          .footer-nav-accessibility {
            display: flex;
            flex-direction: column;
            li {
              padding: 0.3rem 0;
            }
            li:after {
              display: none;
            }
          }			
        }	
      }
    }
  }

  .twiter {
    display: flex;
    &:before {
      content:'';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: $white;
      mask: url(img/x-icon.svg);
      -webkit-mask: url(img/x-icon.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      position: relative;
    }
  }
  .facebook {
    display: flex;
    &:before {
      content:'';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: $white;
      mask: url(img/facebook-icon.svg);
      -webkit-mask: url(img/facebook-icon.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      position: relative;
    }
  }
  .flickr {
    display: flex;
    &:before {
      content:'';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: $white;
      mask: url(img/flickr-icon.svg);
      -webkit-mask: url(img/flickr-icon.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      position: relative;
    }
  }
  .linkedin {
    display: flex;
    &:before {
      content:'';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      background: $white;
      mask: url(img/linkedin-icon.svg);
      -webkit-mask: url(img/linkedin-icon.svg);
      mask-size: cover;
      -webkit-mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      position: relative;
    }
  }







/*
@media only screen 
and (max-width :1280px) {
  footer{
    .footer-elements{
      flex-direction: column;
      justify-content: center;
      .copyright {
        text-align: center;
      }
      .footer-nav-accessibility {
        text-align: center;
        margin: 1rem auto;
      }
      .footer-social-network{
        text-align: center;
        margin: 1rem auto;
      }
    }
  }
}
@media only screen 
and (max-width :680px) {
  footer{
    .footer-elements{
      .copyright {
      }
      .footer-nav-accessibility {
        li{
          display: block;
          padding: 0.5rem 0;
          &:after{
            content:'';
          }
        }
      }
      .footer-social-network{
      }
    }
  }
}
*/
/*** END FOOTEER **/

.go-to{
  display: none;
  margin: 0;
  background: transparent;
  text-align: right;
  position: fixed;
  bottom: 7rem;
  right: 2rem;
  z-index: 9;
  a{
    padding: 0px;
    display:block;
    width: 5rem !important;
    height:5rem;
    border-radius: 6rem;
    background:$aquamarine;
    color:$white;
    line-height: 1rem;
    right: 0px;
    position: relative;
    cursor: pointer;
    &:before{
      content: "\f106";
      font-family: FontAwesome;
      font-size: 3rem;
      color: #FFF;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;  
    }
    &:hover{
      background:$orange;
    }
  }
}


